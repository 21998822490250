import {
    Box,
    FormControlLabel,
    InputLabel,
    Stack,
    Switch,
} from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import {
    CloudConnectorPerifheryEventType,
    EventTypeText,
    getMainEventfromEvents,
    getPeripheryStatefromMainCategory,
    getPeripheryStatusColor,
} from './CloudConnectorPeripheriesOverview.tsx';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { useState } from 'react';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import {
    ConfirmationDialog,
    ConfirmationDialogVersions,
} from '../operator-logins/components/ConfirmationDialog.tsx';
import {
    PeripheryInfo,
    usePeripheryDetailsOutlet,
} from './CloudConnectoPeripheriesDetail.tsx';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import {
    CloudConnectorCommandType,
    isPeripheryDisconnected,
    PeripheryDetailTab,
    PeripheryTab,
    RequestErrorAlert,
    usePeripheryCommand,
} from './CloudConnectorPeripheryDetailTabs.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';

export function PeripheryDetailStatusTab() {
    const { currentLogin } = useOperatorContext();
    const language = useOperatorLanguage();
    const { peripheryConfig } = usePeripheryDetailsOutlet();
    const isDisconnected = isPeripheryDisconnected(peripheryConfig);
    const [peripheryCommandRequestState, executePeripheryCommand] =
        usePeripheryCommand();
    const [openPermanentlyOpenModal, setOpenPermanentlyOpenModal] =
        useState(false);
    const barrierPermanentlyOpen = peripheryConfig.events.some(
        event =>
            event.eventType ===
            CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN,
    );
    useServerErrorEffect(peripheryCommandRequestState, () =>
        setOpenPermanentlyOpenModal(false),
    );
    useServerSuccessEffect(peripheryCommandRequestState, () =>
        setOpenPermanentlyOpenModal(false),
    );
    const mainEvent = getMainEventfromEvents(peripheryConfig.events);
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <PeripheryTab
                selectedTab={PeripheryDetailTab.STATUS}
                isDisconnected={isDisconnected}
            />
            <Stack
                direction={'column'}
                sx={{
                    padding: theme => theme.spacing(3),
                }}
            >
                <RequestErrorAlert
                    open={
                        peripheryCommandRequestState.status ===
                        RequestStatus.ERROR
                    }
                />
                <ReadOnlyTextField
                    sx={{
                        textTransform: 'uppercase',
                        color: theme =>
                            getPeripheryStatusColor(
                                getPeripheryStatefromMainCategory(
                                    mainEvent?.category ?? null,
                                ),
                                theme,
                            ),
                    }}
                    label={
                        <Localized
                            de="Status"
                            fr="Status"
                            it="Stato"
                            en="State"
                        />
                    }
                    value={
                        mainEvent ? (
                            <EventTypeText eventType={mainEvent.eventType} />
                        ) : (
                            <>OK</>
                        )
                    }
                />
                <Box sx={{ paddingTop: theme => theme.spacing(2) }}>
                    <ReadOnlyTextField
                        sx={{
                            textTransform: 'uppercase',
                            color: theme =>
                                mainEvent && mainEvent.outOfOrderInfo
                                    ? theme.palette.error.main
                                    : theme.palette.primary.main,
                        }}
                        label={
                            <Localized
                                de="Probleme"
                                fr="Problèmes"
                                it="Problemi"
                                en="Problems"
                            />
                        }
                        value={
                            mainEvent && mainEvent.outOfOrderInfo ? (
                                <span style={{ whiteSpace: 'pre-line' }}>
                                    {mainEvent.outOfOrderInfo[language]}
                                </span>
                            ) : (
                                EmDash
                            )
                        }
                    />
                </Box>

                <Box
                    sx={{
                        textTransform: 'uppercase',
                        paddingTop: theme => theme.spacing(2),
                    }}
                >
                    <PeripheryInfo peripheryConfig={peripheryConfig} />
                </Box>

                <Box sx={{ paddingTop: theme => theme.spacing(2) }}>
                    <InputLabel
                        sx={{
                            textTransform: 'uppercase',
                        }}
                    >
                        <Localized
                            de="Barriere"
                            fr="Barrière"
                            it="Barriera"
                            en="Barrier"
                        />
                    </InputLabel>
                    <FormControlLabel
                        label={
                            <Box
                                sx={{
                                    color: theme => theme.palette.primary.main,
                                    ...Typo.caption,
                                }}
                            >
                                <Localized
                                    de="dauerhaft offen"
                                    fr="ouverte en permanence"
                                    it="permanentemente aperta"
                                    en="permanently open"
                                />
                            </Box>
                        }
                        control={
                            <Switch
                                checked={barrierPermanentlyOpen}
                                disabled={
                                    peripheryCommandRequestState.status ===
                                        RequestStatus.PENDING || isDisconnected
                                }
                                onChange={(_e, checked) => {
                                    if (!checked) {
                                        executePeripheryCommand({
                                            operatorId: currentLogin.mandantId,
                                            zoneId: peripheryConfig.zoneId,
                                            peripheryType:
                                                peripheryConfig.peripheryType,
                                            onPremiseId:
                                                peripheryConfig.onPremiseId,
                                            commandType:
                                                CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                                            secondaryGate: null,
                                            permanentlyOpen: false,
                                        });
                                    } else {
                                        setOpenPermanentlyOpenModal(true);
                                    }
                                }}
                            />
                        }
                    />
                </Box>
                <ConfirmPermanentlyOpenBarrier
                    open={openPermanentlyOpenModal}
                    onConfirm={() =>
                        executePeripheryCommand({
                            operatorId: currentLogin.mandantId,
                            zoneId: peripheryConfig.zoneId,
                            peripheryType: peripheryConfig.peripheryType,
                            onPremiseId: peripheryConfig.onPremiseId,
                            commandType:
                                CloudConnectorCommandType.BARRIER_PERMANENTLY_OPEN,
                            secondaryGate: null,
                            permanentlyOpen: true,
                        })
                    }
                    onCancel={() => setOpenPermanentlyOpenModal(false)}
                />
            </Stack>
        </Box>
    );
}

function ConfirmPermanentlyOpenBarrier({
    open,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel?: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            title={
                <Localized
                    de="Dauerhafte Öffnung"
                    fr="Ouverture permanente"
                    it="Apertura permanente"
                    en="Permanently open"
                />
            }
            onCancel={onCancel}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="Bestätigen"
                    fr="Confirmer"
                    it="Conferma"
                    en="Confirm"
                />
            }
            dialogVersion={ConfirmationDialogVersions.SOFT_WARNING}
        >
            <p>
                <Localized
                    de="Wollen Sie die Barriere wirklich dauerhaft öffnen?"
                    fr="Voulez-vous vraiment ouvrir la barrière de façon permanente?"
                    it="Vuole veramente aprire in modo permanente la barriera?"
                    en="Do you really want to open the barrier permanently?"
                />
            </p>
        </ConfirmationDialog>
    );
}
