import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import { useNavigate } from 'react-router-dom';
import { useServerWrite } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import Alert from '@mui/material/Alert';
import {
    CloudConnectorPerifheryEventType,
    OperatorZoneBarrierGatePeriphery,
} from './CloudConnectorPeripheriesOverview.tsx';

export enum CloudConnectorCommandType {
    BARRIER_UP_ONCE = 'BARRIER_UP_ONCE',
    BARRIER_PERMANENTLY_OPEN = 'BARRIER_PERMANENTLY_OPEN',
}

export interface ZoneBarrierGatePeripheryCommand {
    operatorId: number;
    zoneId: number;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    commandType: CloudConnectorCommandType;
    secondaryGate: boolean | null;
    permanentlyOpen: boolean | null;
}

export function usePeripheryCommand() {
    return useServerWrite<ZoneBarrierGatePeripheryCommand, null>(
        ({ operatorId, zoneId, peripheryType, onPremiseId }) => ({
            url: `/ui-api/operator-account/${operatorId}/zone-barrier-gate/${zoneId}/periphery/${peripheryType}/${onPremiseId}/command`,
        }),
    );
}

export function isPeripheryDisconnected(
    peripheryConfig: OperatorZoneBarrierGatePeriphery,
): boolean {
    return peripheryConfig.events.some(
        event =>
            event.eventType === CloudConnectorPerifheryEventType.DISCONNECTED,
    );
}

export enum PeripheryDetailTab {
    PRESENCE = 'PRESENCE',
    STATUS = 'STATUS',
}

export function PeripheryTab({
    selectedTab,
    isDisconnected,
}: {
    selectedTab: PeripheryDetailTab;
    isDisconnected: boolean;
}) {
    const navigate = useNavigate();

    return (
        <TabContext value={selectedTab}>
            <TabList
                sx={{
                    backgroundColor: theme => theme.palette.blue.light,
                }}
                onChange={(_, value: PeripheryDetailTab) => {
                    switch (value) {
                        case PeripheryDetailTab.STATUS:
                            navigate('../status');
                            break;
                        case PeripheryDetailTab.PRESENCE:
                            navigate('../presence');
                            break;
                    }
                }}
                variant="fullWidth"
            >
                <Tab
                    label={
                        <Localized
                            de="Präsenz"
                            fr="Présence"
                            it="Presenza"
                            en="Presence"
                        />
                    }
                    value={PeripheryDetailTab.PRESENCE}
                    disabled={isDisconnected}
                    sx={{
                        padding: theme => theme.spacing(2),
                        color: theme => theme.palette.blue.main,
                        fontSize: '16px',
                        fontWeight: 'normal',
                        '&.Mui-selected': {
                            backgroundColor: theme => theme.palette.blue.light,
                            fontWeight: 'bold',
                        },
                    }}
                />
                <Tab
                    label={
                        <Localized
                            de="Details"
                            fr="Détails"
                            it="Dettagli"
                            en="Details"
                        />
                    }
                    value={PeripheryDetailTab.STATUS}
                    sx={{
                        padding: theme => theme.spacing(2),
                        color: theme => theme.palette.blue.main,
                        fontSize: '16px',
                        fontWeight: 'normal',
                        '&.Mui-selected': {
                            backgroundColor: theme => theme.palette.blue.light,
                            fontWeight: 'bold',
                        },
                    }}
                />
            </TabList>
        </TabContext>
    );
}

export function RequestErrorAlert({ open }: { open: boolean }) {
    if (open) {
        return (
            <Alert severity="error" sx={{ margin: '0 0 24px 0' }}>
                <Localized
                    de="Die Aktion konnte nicht ausgeführt werden."
                    fr="L'action n'a pas pu être réalisée."
                    it="Non è stato possibile eseguire l'azione."
                    en="The action could not be carried out."
                />
            </Alert>
        );
    }
    return null;
}
