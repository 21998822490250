import {
    CoinType,
    HopperCoinCount,
    OperatorZoneBarrierGatePeriphery,
    OperatorZoneBarrierGatePeripheryDetails,
    PayStationDetailInfo,
    PeripheryInfoType,
} from './CloudConnectorPeripheriesOverview.tsx';
import {
    generatePath,
    Navigate,
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { isMobileRoute } from '../layout/components/BreadCrumb.tsx';
import { Box, Button, InputLabel, Stack } from '@mui/material';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { Edit } from '@mui/icons-material';
import { HopperConfigType } from 'dg-web-shared/model/ZoneConfiguration.ts';
import { numberToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';

function isCloudConnectorPeripheryType(
    value?: string,
): value is CloudConnectorPeripheryType {
    return Object.values(CloudConnectorPeripheryType).includes(
        value as CloudConnectorPeripheryType,
    );
}

export function getCloudConnectorPeripheryType(
    value?: string,
): CloudConnectorPeripheryType | null {
    if (isCloudConnectorPeripheryType(value)) {
        return value;
    }
    return null;
}

export function usePeripheryDetailsOutlet() {
    return useOutletContext<PeripheryDetailsOutletContext>();
}

function PeripheryDetailOutlet({
    context,
}: {
    context: PeripheryDetailsOutletContext;
}) {
    return <Outlet context={context} />;
}

interface PeripheryDetailsOutletContext {
    peripheryConfig: OperatorZoneBarrierGatePeripheryDetails;
    refetchCloudConnectorPeriphery: () => void;
}

export function PeripheryDetail() {
    const urlParams = usePeripheryDetailUrlParams();
    const operatorId = useOperatorContext().currentLogin.mandantId;
    const [refetchCounter, setRefetchCounter] = useState(0);

    const [peripheryDetailRequestState, refetchPeripheryDetail] =
        useServerFetch<
            OperatorZoneBarrierGatePeripheryDetails,
            {
                operatorId: number;
                zoneId: number;
                peripheryType: CloudConnectorPeripheryType;
                onPremiseId: number;
                refetchCounter: number;
            }
        >(
            context => ({
                url: `/ui-api/operator-account/${context.operatorId}/zone-barrier-gate/${context.zoneId}/periphery/${context.peripheryType}/${context.onPremiseId}`,
            }),
            urlParams
                ? {
                      operatorId: operatorId,
                      zoneId: urlParams.zoneId,
                      peripheryType: urlParams.peripheryType,
                      onPremiseId: urlParams.onPremiseId,
                      refetchCounter: refetchCounter,
                  }
                : null,
        );
    const peripheryConfig = peripheryDetailRequestState.data;
    const isMobile = isMobileRoute();

    useEffect(() => {
        const interval = setInterval(() => {
            setRefetchCounter(prevCount => prevCount + 1);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    if (peripheryDetailRequestState.status === RequestStatus.ERROR) {
        return (
            <Alert severity="error">
                <Localized
                    de="ERROR - Die Daten konnten nicht geladen werden."
                    fr="ERREUR - Les données n'ont pas pu être téléchargées."
                    it="ERRORE - Non è stato possibile caricare i dati."
                    en="ERROR - Data could not be loaded."
                />
            </Alert>
        );
    }

    if (
        peripheryDetailRequestState.status === RequestStatus.NEVER_EXECUTED ||
        peripheryConfig == null
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <OperatorRoutedModal
            variant={isMobile ? ModalVariant.MOBILE : ModalVariant.SMALL}
            backUrl={'..'}
            render={() => (
                <ParkingPortalLayoutWithHeader
                    title={peripheryConfig.name}
                    backTo={'..'}
                >
                    <PeripheryDetailContent peripheryConfig={peripheryConfig} />
                    <PeripheryDetailOutlet
                        context={{
                            peripheryConfig: peripheryConfig,
                            refetchCloudConnectorPeriphery:
                                refetchPeripheryDetail,
                        }}
                    />
                </ParkingPortalLayoutWithHeader>
            )}
        />
    );
}

function PeripheryDetailContent({
    peripheryConfig,
}: {
    peripheryConfig: OperatorZoneBarrierGatePeriphery;
}) {
    switch (peripheryConfig.peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
        case CloudConnectorPeripheryType.EXIT:
            return null;
        case CloudConnectorPeripheryType.PAY_STATION:
            return <PayStationDetails peripheryConfig={peripheryConfig} />;
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
        case CloudConnectorPeripheryType.ACCESS:
            return <Navigate to={'..'} />;
    }
}

function PayStationDetails({
    peripheryConfig,
}: {
    peripheryConfig: OperatorZoneBarrierGatePeriphery;
}) {
    return (
        <Stack
            direction={'column'}
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
                padding: theme => theme.spacing(3),
            }}
        >
            <PeripheryInfo peripheryConfig={peripheryConfig} />
        </Stack>
    );
}

export function PeripheryInfo({
    peripheryConfig,
}: {
    peripheryConfig: OperatorZoneBarrierGatePeriphery;
}) {
    if (peripheryConfig.info == null) {
        return <Box sx={{ display: 'flex', flexGrow: 1 }} />;
    }
    switch (peripheryConfig.info.peripheryInfoType) {
        case PeripheryInfoType.TICKET_REMAINING:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        textTransform: 'uppercase',
                    }}
                >
                    <ReadOnlyTextField
                        label={
                            <Localized
                                de="Verbleibende Tickets"
                                fr="Tickets restants"
                                it="Ticket restanti"
                                en="Remaining tickets"
                            />
                        }
                        value={peripheryConfig.info.ticketRemaining}
                    />
                </Box>
            );
        case PeripheryInfoType.PAY_STATION_INFO: {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    {!peripheryConfig.info.isCashless && (
                        <>
                            <InputLabel
                                sx={{
                                    lineHeight: '1em',
                                    paddingTop: '1px',
                                    textTransform: 'uppercase',
                                }}
                            >
                                <Localized
                                    de="Münzstand"
                                    fr="État de la monnaie"
                                    it="Stato moneta"
                                    en="Coin state"
                                />
                            </InputLabel>
                            <HopperCoinCountInfo
                                payStationDetailInfo={peripheryConfig.info}
                            />
                        </>
                    )}
                </Box>
            );
        }
    }
}

function HopperCoinCountInfo({
    payStationDetailInfo,
}: {
    payStationDetailInfo: PayStationDetailInfo;
}) {
    return (
        <Stack direction={'column'} spacing={1}>
            <Stack direction={'row'} spacing={1}>
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.TOP_LEFT}
                />
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.TOP_RIGHT}
                />
            </Stack>
            <Stack direction={'row'} spacing={1}>
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.BOTTOM_LEFT}
                />
                <HopperCoinCountBox
                    payStationDetailInfo={payStationDetailInfo}
                    hopperPosition={HopperPosition.BOTTOM_RIGHT}
                />
            </Stack>
        </Stack>
    );
}

function HopperCoinCountBox({
    payStationDetailInfo,
    hopperPosition,
}: {
    payStationDetailInfo: PayStationDetailInfo;
    hopperPosition: HopperPosition;
}) {
    const navigate = useNavigate();
    const coinCount = getCoinCountByPosition(
        payStationDetailInfo,
        hopperPosition,
    );
    return (
        <Box
            sx={theme => ({
                color: theme.palette.white.main,
                backgroundColor: theme.palette.primary.main,
                padding: theme.spacing(1.4),
                fontWeight: 700,
                flexGrow: 1,
                aspectRatio: 1.5,
                display: 'flex',
                flexDirection: 'column',
            })}
        >
            {coinCount && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            fontSize: '28px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {`${coinCount.numberOfCoins}x`}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontSize: '16px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            {numberToLocalPrice(
                                'de',
                                coinCount.chfDenomination,
                            )}
                        </Box>
                        <Button
                            sx={{
                                color: theme => theme.palette.white.main,
                                padding: 0,
                                minWidth: '24px !important',
                            }}
                            onClick={() =>
                                navigate(
                                    generatePath(
                                        ':coinType/manual-correction',
                                        coinCount,
                                    ),
                                )
                            }
                        >
                            <Edit
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                }}
                            />
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}

enum HopperPosition {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

function getCoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (info.hopperConfigType) {
        case HopperConfigType.CASHLESS:
            return null;
        case HopperConfigType.NRI_50_200:
            return getNRI_50_200CoinCountByPosition(info, hopperPosition);
        case HopperConfigType.NRI_50_100:
            return getNRI_50_100CoinCountByPosition(info, hopperPosition);
        case HopperConfigType.NRI_100_200:
            return getNRI_100_200CoinCountByPosition(info, hopperPosition);
    }
}

function getNRI_50_200CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_200);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_50);
    }
}

function getNRI_50_100CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_100);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_50);
    }
}

function getNRI_100_200CoinCountByPosition(
    info: PayStationDetailInfo,
    hopperPosition: HopperPosition,
): HopperCoinCount | null {
    switch (hopperPosition) {
        case HopperPosition.TOP_LEFT:
            return getByCoinType(info, CoinType.CHF_200);
        case HopperPosition.TOP_RIGHT:
            return getByCoinType(info, CoinType.CHF_500);
        case HopperPosition.BOTTOM_LEFT:
            return getByCoinType(info, CoinType.CHF_10);
        case HopperPosition.BOTTOM_RIGHT:
            return getByCoinType(info, CoinType.CHF_100);
    }
}

function getByCoinType(info: PayStationDetailInfo, coinType: CoinType) {
    return info.hopperCoinCount.find(c => c.coinType == coinType) ?? null;
}

function usePeripheryDetailUrlParams(): {
    zoneId: number;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
} | null {
    const urlParams = useParams<{
        zoneId: string;
        peripheryType: string;
        onPremiseId: string;
    }>();
    const zoneId = urlParams.zoneId ? parseInt(urlParams.zoneId, 10) : null;
    const peripheryType = getCloudConnectorPeripheryType(
        urlParams.peripheryType,
    );
    const onPremiseId = urlParams.onPremiseId
        ? parseInt(urlParams.onPremiseId, 10)
        : null;
    if (zoneId && peripheryType && onPremiseId) {
        return {
            zoneId,
            peripheryType,
            onPremiseId,
        };
    }
    return null;
}
