import 'core-js/stable';

import { App } from './app/components/App.tsx';
import * as Configuration from './utils/Configuration.ts';
import * as Sentry from './utils/Sentry.ts';
import * as fluxInstance from './fluxInstance.ts';
import { datadogRum } from '@datadog/browser-rum';
import { envIsProduction } from 'dg-web-shared/lib/Environment.ts';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { initUpdater } from 'dg-web-shared/common/utils/UpdateUtils.ts';

// install error tracking
const dsn = Configuration.getSentryProjectDSN();
if (dsn) {
    Sentry.init(dsn);
}

datadogRum.init({
    applicationId: '4d3ef76d-251b-44df-92c8-45b12a4ffd34',
    clientToken: 'pubc1ce4aefc45dd37ab53ee354d2b3722d',
    site: 'datadoghq.eu',
    service: 'parkingportal',
    env: envIsProduction() ? 'prod' : 'test',
    sampleRate: 100,
    trackInteractions: true,
});
datadogRum.startSessionReplayRecording();

type WebkitWindow = {
    webkitRequestAnimationFrame: (callback: FrameRequestCallback) => void;
} & Window &
    typeof globalThis;

window.requestAnimationFrame =
    window.requestAnimationFrame ||
    (<WebkitWindow>window).webkitRequestAnimationFrame ||
    function (f: () => void): void {
        window.setTimeout(f, 1000 / 30);
    };

initUpdater();

const container = document.getElementById('app-container');
const root = createRoot(container!);
root.render(
    React.createElement(App, {
        allState: fluxInstance.flux,
    }),
);
