import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import {
    CheckCircle,
    CreditCard,
    Dangerous,
    Dns,
    ReportProblem,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { Theme } from '@mui/material/styles';
import { ReactNode, useEffect, useState } from 'react';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import Alert from '@mui/material/Alert';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { HopperConfigType } from 'dg-web-shared/model/ZoneConfiguration.ts';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import { GroupSegment } from '../shared-mui-components/GroupSegment.tsx';
import { BarrierGateIdentification } from '../common/components/IdentificationItems.tsx';

interface OperatorPeripheriesList {
    name: string;
    zoneId: number;
    subzones: ConfiguredSubzone[];
    peripheryConfigs: OperatorZoneBarrierGatePeriphery[];
}

interface ConfiguredSubzone {
    zoneBarrierGateSubzoneId: number;
    zoneId: number;
    name: string;
}

export interface OperatorZoneBarrierGatePeriphery {
    zoneId: number;
    onPremiseId: number;
    peripheryType: CloudConnectorPeripheryType;
    zoneBarrierGateSubzoneId: number | null;
    name: string;
    events: CloudConnectorPeripheryEvent[];
    info: PeripheryInfo | null;
}
export interface OperatorZoneBarrierGatePeripheryDetails
    extends OperatorZoneBarrierGatePeriphery {
    presence: PresenceInfo | null;
}

interface PresenceInfo {
    presenceId: string | null;
    lprReadLicensePlate: string | null;
    logInfo: PresenceLogInfo | null;
}

interface PresenceLogInfo {
    identificationMedia: BarrierGateIdentification;
    identificationString: string;
    isSuccess: boolean;
    errorTranslation: Message | null;
}

type PeripheryInfo = TicketRemainingInfo | PayStationDetailInfo;

export enum PeripheryInfoType {
    TICKET_REMAINING = 'TICKET_REMAINING',
    PAY_STATION_INFO = 'PAY_STATION_INFO',
}

interface TicketRemainingInfo {
    peripheryInfoType: PeripheryInfoType.TICKET_REMAINING;
    ticketRemaining: number;
}

export interface PayStationDetailInfo {
    peripheryInfoType: PeripheryInfoType.PAY_STATION_INFO;
    hopperConfigType: HopperConfigType;
    hopperCoinCount: HopperCoinCount[];
    isCashless: boolean;
}

export interface HopperCoinCount {
    coinType: CoinType;
    chfDenomination: number;
    numberOfCoins: number;
}

export enum CoinType {
    CHF_10 = 'CHF_10',
    CHF_20 = 'CHF_20',
    CHF_50 = 'CHF_50',
    CHF_100 = 'CHF_100',
    CHF_200 = 'CHF_200',
    CHF_500 = 'CHF_500',
}

function isCoinType(value?: string): value is CoinType {
    return Object.values(CoinType).includes(value as CoinType);
}

export function getCoinTypeByString(value?: string): CoinType | null {
    if (isCoinType(value)) {
        return value;
    }
    return null;
}

interface CloudConnectorPeripheryEvent {
    onPremiseId: number;
    peripheryType: CloudConnectorPeripheryType;
    eventType: CloudConnectorPerifheryEventType;
    category: CloudConnectorPeripheryStateEventTypeCategory;
    outOfOrderInfo?: Message;
}

export enum CloudConnectorPerifheryEventType {
    BARRIER_PERMANENTLY_OPEN = 'BARRIER_PERMANENTLY_OPEN',
    OUT_OF_ORDER = 'OUT_OF_ORDER',
    DISCONNECTED = 'DISCONNECTED',
    TICKET_ROLL_WARN = 'TICKET_ROLL_WARN',
}

enum CloudConnectorPeripheryStateEventTypeCategory {
    WARNING = 'WARNING',
    ALARM = 'ALARM',
}

enum PeripheryStatus {
    OKAY = 'OKAY',
    ERROR = 'ERROR',
    ALERT = 'ALERT',
}

function ZoneBarrierGatePeripheries() {
    const { currentLogin } = useOperatorContext();
    const [refetchCounter, setRefetchCounter] = useState(0);
    const [peripheriesListRequestState] = useServerFetch<
        OperatorPeripheriesList[],
        { operatorId: number; refetchCounter: number }
    >(
        ({ operatorId }) => ({
            url: `/ui-api/operator-account/${operatorId}/peripheries`,
        }),
        { operatorId: currentLogin.mandantId, refetchCounter: refetchCounter },
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setRefetchCounter(prevCount => prevCount + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    if (peripheriesListRequestState.status === RequestStatus.ERROR) {
        return (
            <Alert severity="error">
                <Localized
                    de="ERROR - Die Daten konnten nicht geladen werden."
                    fr="ERREUR - Les données n'ont pas pu être téléchargées."
                    it="ERRORE - Non è stato possibile caricare i dati."
                    en="ERROR - Data could not be loaded."
                />
            </Alert>
        );
    }

    if (
        peripheriesListRequestState.status === RequestStatus.NEVER_EXECUTED ||
        peripheriesListRequestState.data == null
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <Box sx={{ paddingBottom: '24px' }}>
            {peripheriesListRequestState.data.map(parking => (
                <ParkingSegment parkingData={parking} key={parking.zoneId} />
            ))}
            <Outlet />
        </Box>
    );
}

export function PeripheriesOverviewDesktop() {
    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <Localized
                        de="Geräte"
                        fr="Appareils"
                        it="Apparecchi"
                        en="Devices"
                    />
                }
                icon={Dns}
            />
            <ZoneBarrierGatePeripheries />
        </MainContent>
    );
}

export function PeripheriesOverviewMobile() {
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Geräte"
                    fr="Appareils"
                    it="Apparecchi"
                    en="Devices"
                />
            }
            scrollable
            backTo={'..'}
        >
            <ZoneBarrierGatePeripheries />
        </ParkingPortalLayoutWithHeader>
    );
}

function ParkingSegment({
    parkingData,
}: {
    parkingData: OperatorPeripheriesList;
}) {
    return (
        <GroupSegment label={parkingData.name}>
            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
                direction={{ sm: 'column', md: 'row' }}
                spacing={1}
            >
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.ENTRY,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.PAY_STATION,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.EXIT,
                    )}
                />
                <PeripheryColumn
                    configs={parkingData.peripheryConfigs.filter(
                        peripheryConfig =>
                            peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.ACCESS,
                    )}
                />
            </Stack>
            <TransitSubzones
                configs={parkingData.peripheryConfigs.filter(
                    peripheryConfig =>
                        peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.TRANSIT_ENTRY ||
                        peripheryConfig.peripheryType ===
                            CloudConnectorPeripheryType.TRANSIT_EXIT,
                )}
                subzones={parkingData.subzones}
            />
        </GroupSegment>
    );
}

function TransitSubzones({
    configs,
    subzones,
}: {
    configs: OperatorZoneBarrierGatePeriphery[];
    subzones: ConfiguredSubzone[];
}) {
    if (configs.length === 0) return null;

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            {subzones.map(subzone => (
                <TransitSubzone
                    key={subzone.zoneBarrierGateSubzoneId}
                    configs={configs.filter(
                        peripheryConfig =>
                            (peripheryConfig.peripheryType ===
                                CloudConnectorPeripheryType.TRANSIT_ENTRY ||
                                peripheryConfig.peripheryType ===
                                    CloudConnectorPeripheryType.TRANSIT_EXIT) &&
                            peripheryConfig.zoneBarrierGateSubzoneId ==
                                subzone.zoneBarrierGateSubzoneId,
                    )}
                    subzone={subzone}
                />
            ))}
        </Stack>
    );
}

function TransitSubzone({
    configs,
    subzone,
}: {
    configs: OperatorZoneBarrierGatePeriphery[];
    subzone: ConfiguredSubzone;
}) {
    if (configs.length === 0) return null;

    return (
        <Box>
            <Box
                sx={theme => ({
                    fontSize: '20px',
                    paddingTop: '24px',
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                })}
            >
                {subzone.name}
            </Box>
            <Stack
                sx={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: theme => theme.palette.blue.light,
                }}
                direction={{ sm: 'column', md: 'row' }}
            >
                {configs.map(peripheryConfig => (
                    <Box
                        key={`${peripheryConfig.zoneBarrierGateSubzoneId} - ${peripheryConfig.onPremiseId}`}
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '0px !important',
                            marginLeft: '0px !important',
                            width: '100%',
                            paddingTop: '12px',
                            '&:nth-child(1)': { paddingTop: 0 },
                            [theme.breakpoints.up('md')]: {
                                width: '25%',
                                paddingTop: '0px',
                                paddingLeft: '12px',
                                '&:nth-child(1)': { paddingLeft: 0 },
                            },
                        })}
                    >
                        <PeripheryDisplay
                            key={`${peripheryConfig.onPremiseId}-${peripheryConfig.peripheryType}`}
                            peripheryConfig={peripheryConfig}
                        />
                    </Box>
                ))}
            </Stack>
        </Box>
    );
}

function PeripheryColumn({
    configs,
}: {
    configs: OperatorZoneBarrierGatePeriphery[];
}) {
    if (configs.length === 0) return null;

    return (
        <Box
            sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                marginTop: '0px !important',
                marginLeft: '0px !important',
                width: '100%',
                paddingTop: '12px',
                '&:nth-child(1)': { paddingTop: 0 },
                [theme.breakpoints.up('md')]: {
                    width: '25%',
                    paddingTop: '0px',
                    paddingLeft: '12px',
                    '&:nth-child(1)': { paddingLeft: 0 },
                },
            })}
        >
            {configs.map(peripheryConfig => (
                <PeripheryDisplay
                    key={`${peripheryConfig.onPremiseId}-${peripheryConfig.peripheryType}`}
                    peripheryConfig={peripheryConfig}
                />
            ))}
        </Box>
    );
}

function PeripheryDisplay({
    peripheryConfig,
}: {
    peripheryConfig: OperatorZoneBarrierGatePeriphery;
}) {
    const mainEvent = getMainEventfromEvents(peripheryConfig.events);
    const navigate = useNavigate();
    const detailPath = getPeripheryDetailPath(peripheryConfig);
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'stretch',
                minWidth: '288px',
                minHeight: '80px',
                borderRadius: '12px',
                marginTop: '12px',
                '&:nth-child(1)': { marginTop: 0 },
                cursor: detailPath !== null ? 'pointer' : 'default',
                overflow: 'hidden',
            }}
            onClick={
                detailPath !== null ? () => navigate(detailPath) : undefined
            }
        >
            <StatusIconContent
                status={getPeripheryStatefromMainCategory(
                    mainEvent?.category ?? null,
                )}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '24px',
                    backgroundColor: theme => theme.palette.common.white,
                    width: '100%',
                    flewGrow: 1,
                }}
            >
                <Box
                    sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: theme => theme.palette.primary.main,
                    }}
                >
                    <PeripheryDisplayName
                        peripheryType={peripheryConfig.peripheryType}
                        name={peripheryConfig.name}
                        onPremiseId={peripheryConfig.onPremiseId}
                    />
                </Box>
                {mainEvent ? (
                    <Box
                        sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: theme => theme.palette.primary.main,
                        }}
                    >
                        <EventTypeText eventType={mainEvent.eventType} />
                    </Box>
                ) : (
                    <PeripheryInfoContent info={peripheryConfig.info} />
                )}
            </Box>
        </Box>
    );
}

function PeripheryInfoContent({ info }: { info: PeripheryInfo | null }) {
    if (!info) {
        return null;
    }

    switch (info.peripheryInfoType) {
        case PeripheryInfoType.TICKET_REMAINING: {
            return (
                <InfoSection>
                    <ConfirmationNumberIcon width={17} height={14} />
                    <Localized
                        de={`${info.ticketRemaining} übrig`}
                        fr={`${info.ticketRemaining} restants`}
                        it={`${info.ticketRemaining} restanti`}
                        en={`${info.ticketRemaining} remaining`}
                    />
                </InfoSection>
            );
        }
        case PeripheryInfoType.PAY_STATION_INFO: {
            if (info.isCashless) {
                return (
                    <InfoSection>
                        <CreditCard width={17} height={14} />
                        <Localized
                            de="Cashless"
                            fr="Cashless"
                            it="Cashless"
                            en="Cashless"
                        />
                    </InfoSection>
                );
            } else {
                return null;
            }
        }
    }
}

function InfoSection({ children }: { children: ReactNode }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 500,
                color: theme => theme.palette.grey[600],
                gap: '3px',
            }}
        >
            {children}
        </Box>
    );
}

function getPeripheryDetailPath(
    peripheryConfig: OperatorZoneBarrierGatePeriphery,
): string | null {
    switch (peripheryConfig.peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
        case CloudConnectorPeripheryType.EXIT:
            return generatePath(
                'parking/:zoneId/periphery/:peripheryType/:onPremiseId/detail/presence',
                {
                    zoneId: peripheryConfig.zoneId.toString(),
                    peripheryType: peripheryConfig.peripheryType,
                    onPremiseId: peripheryConfig.onPremiseId.toString(),
                },
            );
        case CloudConnectorPeripheryType.PAY_STATION:
            if (
                peripheryConfig.info != null &&
                peripheryConfig.info.peripheryInfoType ===
                    PeripheryInfoType.PAY_STATION_INFO &&
                !peripheryConfig.info.isCashless
            ) {
                return generatePath(
                    'parking/:zoneId/periphery/:peripheryType/:onPremiseId/detail',
                    {
                        zoneId: peripheryConfig.zoneId.toString(),
                        peripheryType: peripheryConfig.peripheryType,
                        onPremiseId: peripheryConfig.onPremiseId.toString(),
                    },
                );
            } else {
                return null;
            }
        case CloudConnectorPeripheryType.ACCESS:
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
            return null;
    }
}

export function EventTypeText({
    eventType,
}: {
    eventType: CloudConnectorPerifheryEventType;
}): JSX.Element {
    switch (eventType) {
        case CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN:
            return (
                <Localized
                    de="Schranke dauerhaft geöffnet"
                    fr="Barrière ouverte en permanence"
                    it="Barriera permanentemente aperta"
                    en="Barrier permanently open"
                />
            );
        case CloudConnectorPerifheryEventType.OUT_OF_ORDER:
            return (
                <Localized
                    de="Ausser Betrieb"
                    fr="Hors service"
                    it="Fuori servizio"
                    en="Out of order"
                />
            );
        case CloudConnectorPerifheryEventType.DISCONNECTED:
            return (
                <Localized
                    de="Getrennt"
                    fr="Déconnecté"
                    it="Disconnesso"
                    en="Disconnected"
                />
            );
        case CloudConnectorPerifheryEventType.TICKET_ROLL_WARN:
            return (
                <Localized
                    de="Ticketrollenwarnung"
                    fr="Avertissement de rouleau de tickets"
                    it="Avviso di rotolo di biglietti"
                    en="Ticket roll warning"
                />
            );
    }
}

export function getMainEventfromEvents(events: CloudConnectorPeripheryEvent[]) {
    const alarms = events.filter(
        event =>
            event.category ===
            CloudConnectorPeripheryStateEventTypeCategory.ALARM,
    );
    if (alarms.length > 0) {
        return alarms[0];
    }
    const warnings = events.filter(
        event =>
            event.category ===
            CloudConnectorPeripheryStateEventTypeCategory.WARNING,
    );
    if (warnings.length > 0) {
        return warnings[0];
    }
    return null;
}

export function getPeripheryStatefromMainCategory(
    category: CloudConnectorPeripheryStateEventTypeCategory | null,
) {
    switch (category) {
        case CloudConnectorPeripheryStateEventTypeCategory.ALARM:
            return PeripheryStatus.ERROR;
        case CloudConnectorPeripheryStateEventTypeCategory.WARNING:
            return PeripheryStatus.ALERT;
        case null:
            return PeripheryStatus.OKAY;
    }
}

export function PeripheryDisplayName({
    peripheryType,
    onPremiseId,
    name,
}: {
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
    name: string | null;
}) {
    return (
        <>
            <PeripheryPrefix
                peripheryType={peripheryType}
                onPremiseId={onPremiseId}
            />
            {name ? ` - ${name}` : ''}
        </>
    );
}

function PeripheryPrefix({
    peripheryType,
    onPremiseId,
}: {
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number;
}) {
    switch (peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
            return (
                <Localized
                    de={`E${onPremiseId}`}
                    fr={`E${onPremiseId}`}
                    it={`E${onPremiseId}`}
                    en={`E${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.EXIT:
            return (
                <Localized
                    de={`A${onPremiseId}`}
                    fr={`S${onPremiseId}`}
                    it={`U${onPremiseId}`}
                    en={`E${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.PAY_STATION:
            return (
                <Localized
                    de={`K${onPremiseId}`}
                    fr={`C${onPremiseId}`}
                    it={`C${onPremiseId}`}
                    en={`P${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
            return (
                <Localized
                    de={`TE${onPremiseId}`}
                    fr={`TE${onPremiseId}`}
                    it={`TE${onPremiseId}`}
                    en={`TE${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
            return (
                <Localized
                    de={`TA${onPremiseId}`}
                    fr={`TS${onPremiseId}`}
                    it={`TU${onPremiseId}`}
                    en={`TE${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.ACCESS:
            return (
                <Localized
                    de={`Z${onPremiseId}`}
                    fr={`Z${onPremiseId}`}
                    it={`Z${onPremiseId}`}
                    en={`Z${onPremiseId}`}
                />
            );
    }
}

export function getPeripheryStatusColor(
    status: PeripheryStatus,
    theme: Theme,
): string {
    switch (status) {
        case PeripheryStatus.OKAY:
            return theme.palette.success.main;
        case PeripheryStatus.ALERT:
            return theme.palette.warning.main;
        case PeripheryStatus.ERROR:
            return theme.palette.error.main;
    }
}

function StatusIconContent({ status }: { status: PeripheryStatus }) {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '80px',
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                flexGrow: 1,
                backgroundColor: theme =>
                    getPeripheryStatusColor(status, theme),
            }}
        >
            <StatusIcon status={status} />
        </Box>
    );
}

function StatusIcon({ status }: { status: PeripheryStatus }) {
    switch (status) {
        case PeripheryStatus.OKAY:
            return (
                <CheckCircle
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
        case PeripheryStatus.ALERT:
            return (
                <ReportProblem
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
        case PeripheryStatus.ERROR:
            return (
                <Dangerous
                    sx={{
                        fontSize: '55px',
                        color: theme => theme.palette.common.white,
                    }}
                />
            );
    }
}
