import { Box, Stack } from '@mui/material';
import { Localized } from '../common/components/Localized.tsx';
import {
    CloudConnectorPerifheryEventType,
    OperatorZoneBarrierGatePeripheryDetails,
} from './CloudConnectorPeripheriesOverview.tsx';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { BarrierGateIdentificationString } from '../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { ParkingPortalButton } from '../mobile/layout/ParkingPortalButton.tsx';
import { KeyboardControlKey } from '@mui/icons-material';
import { usePeripheryDetailsOutlet } from './CloudConnectoPeripheriesDetail.tsx';
import {
    CloudConnectorCommandType,
    isPeripheryDisconnected,
    PeripheryDetailTab,
    PeripheryTab,
    RequestErrorAlert,
    usePeripheryCommand,
    ZoneBarrierGatePeripheryCommand,
} from './CloudConnectorPeripheryDetailTabs.tsx';
import { Navigate } from 'react-router-dom';

export function PeripheryDetailPresenceTab() {
    const { peripheryConfig } = usePeripheryDetailsOutlet();
    const isDisconnected = isPeripheryDisconnected(peripheryConfig);
    const [peripheryCommandRequestState, executePeripheryCommand] =
        usePeripheryCommand();
    const barrierPermanentlyOpen = peripheryConfig.events.some(
        event =>
            event.eventType ===
            CloudConnectorPerifheryEventType.BARRIER_PERMANENTLY_OPEN,
    );

    if (isDisconnected) {
        return <Navigate to={'../status'} />;
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <PeripheryTab
                selectedTab={PeripheryDetailTab.PRESENCE}
                isDisconnected={isDisconnected}
            />
            <PresenceTabContent
                peripheryConfig={peripheryConfig}
                peripheryCommandRequestState={peripheryCommandRequestState}
                executePeripheryCommand={executePeripheryCommand}
                barrierPermanentlyOpen={barrierPermanentlyOpen}
            />
        </Box>
    );
}

function PresenceTabContent({
    peripheryConfig,
    peripheryCommandRequestState,
    executePeripheryCommand,
    barrierPermanentlyOpen,
}: {
    peripheryConfig: OperatorZoneBarrierGatePeripheryDetails;
    peripheryCommandRequestState: ServerRequestState<null, object>;
    executePeripheryCommand: (payload: ZoneBarrierGatePeripheryCommand) => void;
    barrierPermanentlyOpen: boolean;
}) {
    const { currentLogin } = useOperatorContext();
    const presence = peripheryConfig.presence;

    const hasPresence = presence !== null && presence.presenceId !== null;

    return (
        <Stack
            direction={'column'}
            sx={{
                height: '100%',
                display: 'flex',
                flexGrow: 1,
                padding: theme => theme.spacing(3),
            }}
        >
            <RequestErrorAlert
                open={
                    peripheryCommandRequestState.status === RequestStatus.ERROR
                }
            />
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: theme => theme.palette.primary.main,
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        {hasPresence ? (
                            <Box>
                                <b>
                                    <Localized
                                        de="Fahrzeug anwesend"
                                        fr="Véhicule présent"
                                        it="Veicolo presente"
                                        en="Vehicle present"
                                    />
                                </b>
                                <Box>{presence.lprReadLicensePlate}</Box>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    color: theme => theme.palette.grey.A400,
                                }}
                            >
                                <Localized
                                    de="Kein Fahrzeug"
                                    fr="Aucun véhicule"
                                    it="Nessun veicolo"
                                    en="No vehicle"
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ paddingTop: theme => theme.spacing(3) }}>
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            }
                            value={
                                presence?.logInfo ? (
                                    <BarrierGateIdentificationString
                                        identificationType={
                                            presence.logInfo.identificationMedia
                                        }
                                        identificationString={
                                            presence.logInfo
                                                .identificationString
                                        }
                                    />
                                ) : (
                                    EmDash
                                )
                            }
                        />
                    </Box>

                    <Box sx={{ paddingTop: theme => theme.spacing(2) }}>
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Meldung"
                                    fr="Message"
                                    it="Messaggio"
                                    en="Message"
                                />
                            }
                            value={
                                !hasPresence || presence.logInfo === null ? (
                                    EmDash
                                ) : presence.logInfo.errorTranslation !==
                                  null ? (
                                    <Localized
                                        {...presence.logInfo.errorTranslation}
                                    />
                                ) : peripheryConfig.peripheryType ===
                                  CloudConnectorPeripheryType.ENTRY ? (
                                    <Localized
                                        de="Einfahrt OK"
                                        fr="Entrée OK"
                                        it="Entrata OK"
                                        en="Drive-in OK"
                                    />
                                ) : (
                                    <Localized
                                        de="Ausfahrt OK"
                                        fr="Sortie OK"
                                        it="Uscita OK"
                                        en="Exit OK"
                                    />
                                )
                            }
                            sx={{
                                textTransform: 'uppercase',
                                color: theme =>
                                    !hasPresence || presence.logInfo === null
                                        ? theme.palette.primary.main
                                        : presence.logInfo?.isSuccess === true
                                          ? theme.palette.success.main
                                          : theme.palette.error.main,
                            }}
                        />
                    </Box>
                </Box>

                {!barrierPermanentlyOpen && (
                    <ParkingPortalButton
                        onClick={() =>
                            executePeripheryCommand({
                                operatorId: currentLogin.mandantId,
                                zoneId: peripheryConfig.zoneId,
                                peripheryType: peripheryConfig.peripheryType,
                                onPremiseId: peripheryConfig.onPremiseId,
                                commandType:
                                    CloudConnectorCommandType.BARRIER_UP_ONCE,
                                secondaryGate: false,
                                permanentlyOpen: null,
                            })
                        }
                        disabled={
                            peripheryCommandRequestState.status ===
                            RequestStatus.PENDING
                        }
                        icon={<KeyboardControlKey />}
                    >
                        <Localized
                            de="Barriere öffnen (1x)"
                            fr="Ouvrir la barrière (1x)"
                            it="Apri barriera (1x)"
                            en="Open barrier (1x)"
                        />
                    </ParkingPortalButton>
                )}
            </Box>
        </Stack>
    );
}
