import { useState } from 'react';
import { css } from '@emotion/css';
import { ColorHex } from '../../ui/Colors.ts';
import { CurrentOperatorLoginId } from './CurrentOperatorLogin.tsx';
import { CurrentOperatorLoginDropdown } from './CurrentOperatorLoginDropdown.tsx';
import { SystemStatus } from '../../common/components/SystemStatusLink.tsx';
import { languageFromString } from 'dg-web-shared/lib/Localized.ts';
import {
    NavbarDropdown,
    NavbarDropdownApplicationItem,
    NavbarDropdownBody,
    NavbarDropdownHeader,
    NavbarDropdownItemForeignLink,
    NavbarDropdownVariant,
} from './NavbarDropdown.tsx';
import { useOperatorContext } from '../../app/components/BaseLayoutAndData.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { OperatorAppRoutes } from '../../app/config/OperatorRoutingConfig.tsx';
import { RestrictedComponent } from '../../app/components/RestrictedComponent.tsx';
import { NavLink } from 'react-router-dom';
import { BreadCrumb, getNavigationText } from './BreadCrumb.tsx';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import { TerminalProvider } from '../../common/state/ParkingsState.ts';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as TariffState from '../../tariffs/state/TariffState.ts';
import { DateTime } from 'luxon';
import {
    isRouteAllowed,
    OperatorRouteType,
} from '../../app/components/OperatorRoutes.tsx';
import { Box } from '@mui/material';
import { TermOfUseLink } from '../../landing-page/TermOfUseFiles.tsx';

export function Navbar({
    parkings,
    zones,
    permitTypes,
    tariffs,
}: {
    parkings: ParkingsState.State;
    zones: MasterDataZonesState.State;
    permitTypes: PermitTypeState.State;
    tariffs: TariffState.List.State;
}) {
    const { operatorData, settings } = useOperatorContext();

    const [show, setShow] = useState(false);

    return (
        <div
            className={css({
                position: 'absolute',
                top: 0,
                width: '100%',
                height: 48,
                background:
                    window.location.hostname !== 'parkingportal.ch'
                        ? ColorHex.fPendent
                        : ColorHex.navbar,
                color: '#FFFFFF',
            })}
        >
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                })}
            >
                <NavbarDropdown onClose={() => setShow(false)}>
                    <NavbarDropdownHeader
                        open={show}
                        hasDropDownIcon={false}
                        onClick={() => setShow(!show)}
                    >
                        <BreadCrumb />
                    </NavbarDropdownHeader>
                    <NavbarDropdownBody
                        open={show}
                        variant={NavbarDropdownVariant.Left}
                    >
                        <NavbarBody
                            onClose={() => setShow(false)}
                            parkings={parkings}
                            zones={zones}
                            permitTypes={permitTypes}
                            tariffs={tariffs}
                        />
                        <div
                            className={css({
                                height: 48,
                                padding: '16px 32px 12px 12px',
                                color: '#fff',
                                background: '#32496B',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '13px',
                                fontWeight: 500,
                            })}
                        >
                            <Localized
                                de="Hilfe"
                                fr="Aide"
                                it="Aiuto"
                                en="Help"
                            />
                            &#58; &nbsp;
                            <a
                                className={css({ color: '#fff' })}
                                href="mailto:info@parkingportal.ch"
                            >
                                info@parkingportal.ch
                            </a>
                            &nbsp; &#47; &nbsp;
                            <a
                                className={css({ color: '#fff' })}
                                href="tel:+41848330444"
                            >
                                0848 330 444
                            </a>
                        </div>
                    </NavbarDropdownBody>
                </NavbarDropdown>

                <div
                    className={css({
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingRight: 8,
                        alignItems: 'center',
                    })}
                >
                    <CurrentOperatorLoginId />
                    <CurrentOperatorLoginDropdown />
                    <SystemStatus />
                    <TermOfUseLink
                        lang={languageFromString(settings.language)}
                        hasContractVersionAVBV2={
                            operatorData.hasContractVersionAVBV2
                        }
                    />
                </div>
            </div>
        </div>
    );
}

const navLinkClass = css({
    padding: '12px 14px',
    color: '#fff',
    display: 'block',
});

const activeNavLinkClass = css({
    color: '#4f9adb',
});

function NavbarBody({
    onClose,
    parkings,
    zones,
    permitTypes,
    tariffs,
}: {
    onClose: () => void;
    parkings: ParkingsState.State;
    zones: MasterDataZonesState.State;
    permitTypes: PermitTypeState.State;
    tariffs: TariffState.List.State;
}) {
    const { operatorData, currentLogin } = useOperatorContext();
    const hasTariffs = tariffs.data.length > 0;
    const hasPermitTypes =
        permitTypes.data.filter(
            permitType => permitType.operatorState == 'ACTIVE',
        ).length > 0;
    const hasParkings = parkings.data.length > 0;
    const hasZones = zones.data.filter(z => !z.isFromForeignMandant).length > 0;
    const currentYear = DateTime.now().year;

    return (
        <div
            className={css({
                fontFamily: 'Roboto',
                fontSize: 14,
                display: 'flex',
                letterSpacing: '0.02em',
                lineHeight: '16px',
                fontWeight: 500,
            })}
        >
            <div>
                <NavbarDropdownApplicationItem
                    label={
                        <Localized
                            de="Allgemein"
                            fr="Général"
                            it="Generale"
                            en="General"
                        />
                    }
                />

                <StandardNavItem
                    onClose={onClose}
                    path={'/'}
                    route={OperatorAppRoutes.Home}
                />
                {(hasZones || hasPermitTypes || hasParkings) && (
                    <StandardNavItem
                        onClose={onClose}
                        path={'/activity-statement'}
                        route={OperatorAppRoutes.ActivityStatement}
                    />
                )}
                {hasTariffs && (
                    <StandardNavItem
                        onClose={onClose}
                        path={'/tariffs'}
                        route={OperatorAppRoutes.Tariffs}
                    />
                )}
                <StandardNavItem
                    onClose={onClose}
                    path={'/logins'}
                    route={OperatorAppRoutes.Logins}
                />
                <StandardNavItem
                    onClose={onClose}
                    path={`/statistics?YEAR=${currentYear}%2C${
                        currentYear - 1
                    }`}
                    route={OperatorAppRoutes.Statistics}
                />
            </div>

            {(hasRoute(OperatorAppRoutes.OnstreetTransactions) ||
                hasRoute(OperatorAppRoutes.TomTransactions) ||
                hasRoute(OperatorAppRoutes.ControlHistory) ||
                hasRoute(OperatorAppRoutes.Zones)) && (
                <div>
                    <NavbarDropdownApplicationItem
                        label={
                            <Localized
                                de="Onstreet"
                                fr="Onstreet"
                                en="Onstreet"
                                it="Onstreet"
                            />
                        }
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/onstreet-transactions'}
                        route={OperatorAppRoutes.OnstreetTransactions}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/tom-transactions'}
                        route={OperatorAppRoutes.TomTransactions}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/control-history'}
                        route={OperatorAppRoutes.ControlHistory}
                    />
                    <HorizontalSeperator
                        routeType={OperatorRouteType.Onstreet}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/zones'}
                        route={OperatorAppRoutes.Zones}
                    />
                    {operatorData.hasTaxomexModule &&
                        currentLogin.permissions.taxomexModule && (
                            <>
                                <StandardNavItem
                                    onClose={onClose}
                                    path={'/parking-meters'}
                                    route={OperatorAppRoutes.ParkingMeters}
                                />
                                <StandardNavItem
                                    onClose={onClose}
                                    path={'/usb-sticks'}
                                    route={OperatorAppRoutes.UsbSticks}
                                />
                            </>
                        )}
                </div>
            )}

            {operatorData.existingOffstreetTransactionsType != null && (
                <div>
                    <NavbarDropdownApplicationItem
                        label={
                            <Localized
                                de="Offstreet"
                                fr="Offstreet"
                                en="Offstreet"
                                it="Offstreet"
                            />
                        }
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/offstreet-transactions'}
                        route={OperatorAppRoutes.OffstreetTransactions}
                    />
                    {checkHasBarrierGateParking(parkings) && (
                        <StandardNavItem
                            onClose={onClose}
                            path={'/device-history'}
                            route={OperatorAppRoutes.PeripheryHistory}
                        />
                    )}
                    <HorizontalSeperator
                        routeType={OperatorRouteType.Offstreet}
                        hasParkings={hasParkings}
                    />
                    {hasParkings && (
                        <StandardNavItem
                            onClose={onClose}
                            path={'/parkings'}
                            route={OperatorAppRoutes.Parkings}
                        />
                    )}
                    <StandardNavItem
                        onClose={onClose}
                        path={'/offstreet-devices'}
                        route={OperatorAppRoutes.CloudConnectorPeripheries}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/counters'}
                        route={OperatorAppRoutes.CloudConnectorCounterOverview}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/pay-station-statement'}
                        route={OperatorAppRoutes.PayStationStatement}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/pay-station-collections'}
                        route={OperatorAppRoutes.PayStationCollections}
                    />
                </div>
            )}

            {currentLogin.permissions.parkingpayModule &&
                (hasRoute(OperatorAppRoutes.ClearancesPermitsList) ||
                    hasRoute(OperatorAppRoutes.ClearanceRequests) ||
                    hasRoute(OperatorAppRoutes.CustomerAccounts) ||
                    hasRoute(OperatorAppRoutes.SpecialReports) ||
                    hasPermitTypes) && (
                    <div>
                        <NavbarDropdownApplicationItem
                            label={
                                <Localized
                                    de="Parkingpay"
                                    fr="Parkingpay"
                                    en="Parkingpay"
                                    it="Parkingpay"
                                />
                            }
                        />
                        <StandardNavItem
                            onClose={onClose}
                            path={'/clearance-permit-list'}
                            route={OperatorAppRoutes.ClearancesPermitsList}
                        />
                        <StandardNavItem
                            onClose={onClose}
                            path={'/clearance-requests'}
                            route={OperatorAppRoutes.ClearanceRequests}
                        />
                        <StandardNavItem
                            onClose={onClose}
                            path={'/customer-accounts'}
                            route={OperatorAppRoutes.CustomerAccounts}
                        />
                        <StandardNavItem
                            onClose={onClose}
                            path={'/special-reports'}
                            route={OperatorAppRoutes.SpecialReports}
                        />
                        <HorizontalSeperator
                            routeType={OperatorRouteType.ParkingPay}
                            hasPermitTypes={hasPermitTypes}
                        />
                        {hasPermitTypes && (
                            <StandardNavItem
                                onClose={onClose}
                                path={'/permit-types'}
                                route={OperatorAppRoutes.PermitTypes}
                            />
                        )}
                    </div>
                )}

            {(operatorData.numberOfActiveTenants > 0 ||
                operatorData.hasActiveParkingaboProducts) && (
                <div>
                    <NavbarDropdownApplicationItem
                        label={
                            <Localized
                                de="Parkingabo"
                                fr="Parkingabo"
                                it="Parkingabo"
                                en="Parkingabo"
                            />
                        }
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={
                            '/parkingabo/users?STATUS=ACTIVE%2CLOCKED%2CPENDING'
                        }
                        route={OperatorAppRoutes.ParkingaboUsers}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/parkingabo/products?STATUS=ACTIVE'}
                        route={OperatorAppRoutes.ParkingaboProducts}
                    />
                    <HorizontalSeperator
                        routeType={OperatorRouteType.Parkingabo}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/parkingabo/product-types'}
                        route={OperatorAppRoutes.ParkingaboProductTypes}
                    />
                    <StandardNavItem
                        onClose={onClose}
                        path={'/parkingabo/user-categories'}
                        route={OperatorAppRoutes.ParkingaboUserCategories}
                    />
                </div>
            )}

            {(operatorData.parkomaticSmartfolio ||
                operatorData.taxomexMiofficeIframe ||
                operatorData.taxomexParkMonitorIframe) &&
                !operatorData.enforcementOnly && (
                    <div>
                        <NavbarDropdownApplicationItem
                            label={
                                <Localized
                                    de="Externe Module"
                                    fr="Modules externes"
                                    it="Moduli esterni"
                                    en="External Modules"
                                />
                            }
                        />
                        <StandardNavItem
                            onClose={onClose}
                            path={'/parkmonitor'}
                            route={OperatorAppRoutes.Parkmonitor}
                        />
                        {operatorData.parkomaticSmartfolio && (
                            <NavbarDropdownItemForeignLink
                                label={
                                    <Localized
                                        de="Smartfolio"
                                        fr="Smartfolio"
                                        it="Smartfolio"
                                        en="Smartfolio"
                                    />
                                }
                                link={'https://my.parkfolio.com/'}
                            />
                        )}
                        <StandardNavItem
                            onClose={onClose}
                            path={'/mi-office'}
                            route={OperatorAppRoutes.MiOffice}
                        />
                    </div>
                )}
        </div>
    );
}

function checkHasBarrierGateParking(parkings: ParkingsState.State) {
    const firstFoundVborPmParking = parkings.data.find(
        parking =>
            parking.terminalProvider === TerminalProvider.VB ||
            parking.terminalProvider === TerminalProvider.PM ||
            parking.terminalProvider === TerminalProvider.Sitax ||
            parking.terminalProvider === TerminalProvider.DESIGNA ||
            parking.terminalProvider === TerminalProvider.HUB_PARKING ||
            parking.terminalProvider === TerminalProvider.CLOUD_CONNECTOR,
    );

    return Boolean(firstFoundVborPmParking);
}

function StandardNavItem({
    onClose,
    route,
    path,
}: {
    onClose: () => void;
    route: OperatorAppRoutes;
    path: string;
}) {
    const { operatorData } = useOperatorContext();

    return (
        <RestrictedComponent route={route}>
            <NavLink
                to={path}
                className={({ isActive }) =>
                    `${navLinkClass} ${isActive ? activeNavLinkClass : ''}`
                }
                onClick={onClose}
            >
                {getNavigationText(route, operatorData)}
            </NavLink>
        </RestrictedComponent>
    );
}

function HorizontalSeperator({
    routeType,
    hasParkings,
    hasPermitTypes,
}: {
    routeType: OperatorRouteType;
    hasParkings?: boolean;
    hasPermitTypes?: boolean;
}): JSX.Element | null {
    function hasHorizontalSeperator(): boolean {
        switch (routeType) {
            case OperatorRouteType.Default:
            case OperatorRouteType.External:
                return false;
            case OperatorRouteType.Onstreet:
                return (
                    hasRoute(OperatorAppRoutes.Zones) ||
                    hasRoute(OperatorAppRoutes.ParkingMeters) ||
                    hasRoute(OperatorAppRoutes.UsbSticks)
                );
            case OperatorRouteType.Offstreet:
                return (
                    Boolean(hasParkings) && hasRoute(OperatorAppRoutes.Parkings)
                );
            case OperatorRouteType.ParkingPay:
                return (
                    Boolean(hasPermitTypes) &&
                    hasRoute(OperatorAppRoutes.PermitTypes)
                );
            case OperatorRouteType.Parkingabo:
                return (
                    hasRoute(OperatorAppRoutes.ParkingaboProductTypes) ||
                    hasRoute(OperatorAppRoutes.ParkingaboUserCategories)
                );
        }
    }

    if (hasHorizontalSeperator()) {
        return (
            <Box sx={{ padding: '0 14px' }}>
                <hr />
            </Box>
        );
    } else {
        return null;
    }
}

function hasRoute(appRoute: OperatorAppRoutes) {
    const { operatorData, currentLogin } = useOperatorContext();
    return isRouteAllowed(operatorData, currentLogin, appRoute);
}
