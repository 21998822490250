import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { Localized, useLocalized } from '../common/components/Localized.tsx';
import { BarChart, CloudDownload } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import {
    DataPointUnit,
    numberFormatter,
    OperatorBarChart,
} from './OperatorBarChart.tsx';
import { FilterBar } from '../shared-mui-components/filter/FiltersBox.tsx';
import { useStatisticsFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { OperatorParkingaboUsersFilters } from '../parkingabo-users/OperatorParkingaboUsersFilterList.tsx';
import {
    buildFilterPayload,
    setSearchURL,
} from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonWithDropdown } from '../layout/components/ButtonWithDropdown.tsx';
import { Fragment } from 'react';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { openNewTab } from 'dg-web-shared/common/utils/Navigation.tsx';

export interface CheckinStatisticResponse {
    data: CheckinStatisticYear[];
    maxMonthTotalRevenue: number;
    maxMonthTotalCount: number;
}

interface CheckinStatisticYear {
    year: number;
    totalRevenue: number;
    totalCount: number;
    months: CheckinStatisticMonth[];
}

enum AggregateDuration {
    MONTHLY = 'MONTHLY',
    DAILY = 'DAILY',
}

interface CheckinStatisticMonth {
    yearMonth: string;
    countTotal: number;
    revenueTotal: number;
    countParkingpay: number;
    revenueParkingpay: number;
    countTwint: number;
    revenueTwint: number;
    countEasypark: number;
    revenueEasypark: number;
    countCoop: number;
    revenueCoop: number;
    countTcs: number;
    revenueTcs: number;
    countSwisspass: number;
    revenueSwisspass: number;
    countPnR: number;
    revenuePnR: number;
    countParkingabo: number;
    revenueParkingabo: number;
    countParkingaboCounter: number;
    revenueParkingaboCounter: number;
    countQuickcheckout: number;
    revenueQuickcheckout: number;
    countTom: number;
    revenueTom: number;
}

export function OperatorStatisticsRoute() {
    const {
        filterConfig,
        activeFilters,
        listState,
        filterConfigState,
        searchText,
    } = useStatisticsFilterConfiguration();
    const language = useOperatorLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const payStation = useLocalized({
        de: 'Kasse',
        fr: 'Caisse',
        it: 'Cassa',
        en: 'Pay station',
    });
    const parkingaboCounter = useLocalized({
        de: 'Parkingabo (Schalter)',
        fr: 'Parkingabo (guichet)',
        it: 'Parkingabo (sportello)',
        en: 'Parkingabo (counter)',
    });
    const parkingaboEcommerce = useLocalized({
        de: 'Parkingabo (E-Commerce)',
        fr: 'Parkingabo (e-commerce)',
        it: 'Parkingabo (e-commerce)',
        en: 'Parkingabo (e-commerce)',
    });
    const tooltipNames = [
        'TWINT',
        'Parkingpay',
        'EasyPark',
        'Coop',
        'TCS',
        'SwissPass',
        'P+Rail',
        parkingaboEcommerce,
        parkingaboCounter,
        'Quick-Checkout',
        'TOMeco',
        payStation,
    ];

    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <Localized
                        de="Statistiken"
                        fr="Statistiques"
                        it="Statistiche"
                        en="Statistics"
                    />
                }
                icon={BarChart}
                headerButtons={
                    <ButtonWithDropdown
                        label={
                            <Localized
                                de="Exportieren"
                                fr="Exporter"
                                it="Esporta"
                                en="Export"
                            />
                        }
                        itemVariant={'action'}
                        variant="outlined"
                        startIcon={<CloudDownload />}
                        dropdownItems={[
                            {
                                key: 'lock',
                                label: (
                                    <Localized
                                        de="Gruppiert nach Tag"
                                        fr="Groupée par jour"
                                        it="Raggruppata per giorno"
                                        en="Grouped by day"
                                    />
                                ),
                                disabled: false,
                                onClickCallback: () => {
                                    openNewTab(
                                        `/ui-api/operator-account/statistics/export/xlsx?lang=${language}&aggregateDuration=${
                                            AggregateDuration.DAILY
                                        }&${buildFilterPayload(
                                            activeFilters,
                                            searchText,
                                        )}`,
                                    );
                                },
                            },
                            {
                                key: 'lock',
                                label: (
                                    <Localized
                                        de="Gruppiert nach Monat"
                                        fr="Groupée par mois"
                                        it="Raggruppata per mese"
                                        en="Grouped by month"
                                    />
                                ),
                                disabled: false,
                                onClickCallback: () => {
                                    openNewTab(
                                        `/ui-api/operator-account/statistics/export/xlsx?lang=${language}&aggregateDuration=${
                                            AggregateDuration.MONTHLY
                                        }&${buildFilterPayload(
                                            activeFilters,
                                            searchText,
                                        )}`,
                                    );
                                },
                            },
                        ]}
                    />
                }
            />
            <FilterBar>
                <OperatorParkingaboUsersFilters
                    activeFilters={activeFilters}
                    filterConfig={filterConfig}
                    onFiltersStateChange={activeFilters =>
                        setSearchURL(
                            navigate,
                            location,
                            searchText,
                            activeFilters,
                        )
                    }
                    pending={
                        filterConfigState.status === RequestStatus.PENDING &&
                        Object.keys(filterConfig).length === 0
                    }
                />
            </FilterBar>
            <ScrollableContainer>
                <OperatorAsyncLoadedSection
                    requestState={listState}
                    render={data => (
                        <>
                            {data.data.map(yearData => (
                                <Fragment key={yearData.year}>
                                    <Typography
                                        component="div"
                                        variant="h6"
                                        sx={{
                                            backgroundColor: theme =>
                                                theme.palette.primary.main,
                                            color: theme =>
                                                theme.palette.primary
                                                    .contrastText,
                                            fontWeight: theme =>
                                                theme.typography.fontWeightBold,
                                            textAlign: 'center',
                                            marginX: 4,
                                            marginTop: 2,
                                        }}
                                    >
                                        {yearData.year}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={4}
                                        sx={{
                                            margin: 4,
                                            marginTop: 2,
                                            marginBottom: 8,
                                        }}
                                    >
                                        <ChartSection
                                            title={
                                                <>
                                                    <Localized
                                                        de="Anzahl Parkvorgänge "
                                                        fr="Nombre de procédures "
                                                        it="Numero procedure "
                                                        en="Number of procedures "
                                                    />
                                                </>
                                            }
                                            caption={
                                                <>
                                                    (
                                                    {numberFormatter(
                                                        yearData.totalCount,
                                                        DataPointUnit.PCS,
                                                        false,
                                                    )}
                                                    )
                                                </>
                                            }
                                        >
                                            <OperatorBarChart
                                                data={yearData.months}
                                                maxYValue={
                                                    data.maxMonthTotalCount
                                                }
                                                totalKey="countTotal"
                                                dataKeys={[
                                                    'countTwint',
                                                    'countParkingpay',
                                                    'countEasypark',
                                                    'countCoop',
                                                    'countTcs',
                                                    'countSwisspass',
                                                    'countPnR',
                                                    'countParkingabo',
                                                    'countParkingaboCounter',
                                                    'countQuickcheckout',
                                                    'countTom',
                                                    'countPayStation',
                                                ]}
                                                tooltipNames={tooltipNames}
                                                yearMonthKey="yearMonth"
                                            />
                                        </ChartSection>
                                        <ChartSection
                                            title={
                                                <>
                                                    <Localized
                                                        de="Einnahmen "
                                                        fr="Recettes "
                                                        it="Entrate "
                                                        en="Revenue "
                                                    />
                                                </>
                                            }
                                            caption={
                                                <>
                                                    (
                                                    {numberFormatter(
                                                        yearData.totalRevenue,
                                                        DataPointUnit.CHF,
                                                        true,
                                                    )}
                                                    )
                                                </>
                                            }
                                        >
                                            <OperatorBarChart
                                                data={yearData.months}
                                                maxYValue={
                                                    data.maxMonthTotalRevenue
                                                }
                                                totalKey="revenueTotal"
                                                dataKeys={[
                                                    'revenueTwint',
                                                    'revenueParkingpay',
                                                    'revenueEasypark',
                                                    'revenueCoop',
                                                    'revenueTcs',
                                                    'revenueSwisspass',
                                                    'revenuePnR',
                                                    'revenueParkingabo',
                                                    'revenueParkingaboCounter',
                                                    'revenueQuickcheckout',
                                                    'revenueTom',
                                                    'revenuePayStation',
                                                ]}
                                                tooltipNames={tooltipNames}
                                                yearMonthKey="yearMonth"
                                                unit={DataPointUnit.CHF}
                                            />
                                        </ChartSection>
                                    </Stack>
                                </Fragment>
                            ))}
                        </>
                    )}
                />
            </ScrollableContainer>
        </MainContent>
    );
}

function ChartSection({
    title,
    caption,
    children,
}: {
    title: React.ReactNode;
    caption: React.ReactNode;
    children: React.ReactNode;
}) {
    return (
        <Box sx={{ width: '100%' }}>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
                spacing={1}
                sx={{ marginBottom: 2 }}
            >
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h6">{caption}</Typography>
            </Stack>
            {children}
        </Box>
    );
}

function ScrollableContainer({ children }: { children: React.ReactNode }) {
    return <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>{children}</Box>;
}
