import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { useCloudConnectorPeripheryHistoryFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { History } from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import { FiltersBox } from '../shared-mui-components/filter/FiltersBox.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    EmptyResults,
    FetchError,
    ResultLimitAlert,
} from '../shared-mui-components/ListMessages.tsx';
import {
    BarrierGateIdentificationIcon,
    BarrierGateIdentificationString,
} from '../offstreet-transactions/OperatorOffstreetTransactionsRoute.tsx';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { BarrierGateIdentification } from '../common/components/IdentificationItems.tsx';
import { ColorHex } from '../ui/Colors.ts';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Message } from 'dg-web-shared/lib/Localized.ts';

interface PeripheryHistoryEntry {
    peripheryLogId: number;
    createdAt: string;
    identificationMedia: BarrierGateIdentification;
    identificationString: string;
    zone: string;
    city: string;
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number | null;
    isSuccess: boolean;
    message: Message | null;
}

export interface PeripheryHistorySearchResult {
    results: PeripheryHistoryEntry[];
    eof: boolean;
}

export function PeripheryHistory() {
    const { operatorData } = useOperatorContext();
    const {
        filterConfig,
        activeFilters,
        listState,
        refetchList,
        searchText,
        filterConfigState,
    } = useCloudConnectorPeripheryHistoryFilterConfiguration();

    if (operatorData.existingOffstreetTransactionsType === null) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <Localized
                        de="Geräte-History"
                        fr="Historique des appareils"
                        it="Storico apparecchi"
                        en="Devices History"
                    />
                }
                icon={History}
            />
            <FiltersBox
                searchText={searchText}
                activeFilters={activeFilters}
                filterConfig={filterConfig}
                requestState={listState}
                refetch={refetchList}
                pending={filterConfigState.status === RequestStatus.PENDING}
                tooltip={<PeripheryHistorySearchTooltip />}
            />
            <OperatorAsyncLoadedSection
                requestState={listState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                renderError={() => <FetchError refetchList={refetchList} />}
                render={data => <PeripheryHistoryList data={data} />}
            />
        </MainContent>
    );
}

function PeripheryHistoryList({
    data,
}: {
    data: PeripheryHistorySearchResult;
}) {
    if (data.results.length === 0) {
        return <EmptyResults />;
    }

    return (
        <>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Localized
                                    de="Datum"
                                    fr="Date"
                                    it="Data"
                                    en="Date"
                                />
                            </TableCell>
                            <TableCell>
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            </TableCell>
                            <TableCell>
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            </TableCell>
                            <TableCell>
                                <Localized
                                    de="Gerät"
                                    fr="Appareil"
                                    it="Apparecchio"
                                    en="Device"
                                />
                            </TableCell>
                            <TableCell>
                                <Localized
                                    de="Nachricht"
                                    fr="Message"
                                    it="Messaggio"
                                    en="Message"
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.results.map(historyEntry => (
                            <HistoryEntryRow
                                key={historyEntry.peripheryLogId}
                                historyEntry={historyEntry}
                            />
                        ))}
                    </TableBody>
                </Table>
                <ResultLimitAlert
                    eof={data.eof}
                    numberOfResults={data.results.length}
                    hasExport={false}
                />
            </TableContainer>
        </>
    );
}

function HistoryEntryRow({
    historyEntry,
}: {
    historyEntry: PeripheryHistoryEntry;
}) {
    return (
        <TableRow
            sx={{
                backgroundColor: theme =>
                    historyEntry.isSuccess
                        ? theme.palette.white.main
                        : ColorHex.rgba(theme.palette.error.main, 0.1),
            }}
        >
            <TableCell>
                {Formatter.isoStringTodayMonthYearhHourMinuteSecond(
                    historyEntry.createdAt,
                )}
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex' }}>
                    <BarrierGateIdentificationIcon
                        identificationType={historyEntry.identificationMedia}
                        licensePlateType={null}
                    />
                    <BarrierGateIdentificationString
                        sx={{ paddingLeft: '5px' }}
                        identificationType={historyEntry.identificationMedia}
                        identificationString={historyEntry.identificationString}
                    />
                </Box>
            </TableCell>
            <TableCell>
                {`${historyEntry.zone} ${EmDash} ${historyEntry.city}`}
            </TableCell>
            <TableCell>
                <PeripheryName
                    peripheryType={historyEntry.peripheryType}
                    onPremiseId={historyEntry.onPremiseId}
                />
            </TableCell>
            <TableCell>
                {historyEntry.message && (
                    <Localized {...historyEntry.message} />
                )}
            </TableCell>
        </TableRow>
    );
}

function PeripheryName({
    peripheryType,
    onPremiseId,
}: {
    peripheryType: CloudConnectorPeripheryType;
    onPremiseId: number | null;
}) {
    if (onPremiseId === null) {
        switch (peripheryType) {
            case CloudConnectorPeripheryType.ENTRY:
                return (
                    <Localized
                        de="Einfahrt"
                        fr="Entré"
                        it="Entrata"
                        en="Entry"
                    />
                );
            case CloudConnectorPeripheryType.EXIT:
                return (
                    <Localized
                        de="Ausfahrt"
                        fr="Sortie"
                        it="Uscita"
                        en="Exit"
                    />
                );
            case CloudConnectorPeripheryType.PAY_STATION:
                return (
                    <Localized
                        de="Kasse"
                        fr="Caisse"
                        it="Cassa"
                        en="Pay station"
                    />
                );
            case CloudConnectorPeripheryType.TRANSIT_ENTRY:
                return (
                    <Localized
                        de="Einfahrt Subzone"
                        fr="Entré sous-zone"
                        it="Entrata sottozona"
                        en="Entry subzone"
                    />
                );
            case CloudConnectorPeripheryType.TRANSIT_EXIT:
                return (
                    <Localized
                        de="Ausfahrt Subzone"
                        fr="Sortie sous-zone"
                        it="Uscita sottozona"
                        en="Exit subzone"
                    />
                );
            case CloudConnectorPeripheryType.ACCESS:
                return (
                    <Localized
                        de="Zugang"
                        fr="Point d'accès"
                        it="Punto di accesso"
                        en="Access Point"
                    />
                );
        }
    }
    switch (peripheryType) {
        case CloudConnectorPeripheryType.ENTRY:
            return (
                <Localized
                    de={`Einfahrt ${onPremiseId}`}
                    fr={`Entré ${onPremiseId}`}
                    it={`Entrata ${onPremiseId}`}
                    en={`Entry ${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.EXIT:
            return (
                <Localized
                    de={`Ausfahrt ${onPremiseId}`}
                    fr={`Sortie ${onPremiseId}`}
                    it={`Uscita ${onPremiseId}`}
                    en={`Exit ${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.PAY_STATION:
            return (
                <Localized
                    de={`Kasse ${onPremiseId}`}
                    fr={`Caisse ${onPremiseId}`}
                    it={`Cassa ${onPremiseId}`}
                    en={`Pay station ${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_ENTRY:
            return (
                <Localized
                    de={`Einfahrt Subzone ${onPremiseId}`}
                    fr={`Entré sous-zone ${onPremiseId}`}
                    it={`Entrata sottozona ${onPremiseId}`}
                    en={`Entry subzone ${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.TRANSIT_EXIT:
            return (
                <Localized
                    de={`Ausfahrt Subzone ${onPremiseId}`}
                    fr={`Sortie sous-zone ${onPremiseId}`}
                    it={`Uscita sottozona ${onPremiseId}`}
                    en={`Exit subzone ${onPremiseId}`}
                />
            );
        case CloudConnectorPeripheryType.ACCESS:
            return (
                <Localized
                    de={`Zugang ${onPremiseId}`}
                    fr={`Point d'accès ${onPremiseId}`}
                    it={`Punto di accesso ${onPremiseId}`}
                    en={`Access Point ${onPremiseId}`}
                />
            );
    }
}

function PeripheryHistorySearchTooltip() {
    return (
        <Box
            sx={{
                fontSize: 14,
                padding: '18px 18px 5px 18px',
                width: '300px',
            }}
        >
            <Localized
                de={
                    <>
                        Verfügbare Suchkriterien:
                        <ul>
                            <li>Ticketnummer</li>
                            <li>Badgenummer</li>
                            <li>Kennzeichen</li>
                            <li>QR-Code Nummer</li>
                        </ul>
                    </>
                }
                fr={
                    <>
                        Critères de recherche disponibles:
                        <ul>
                            <li>numéro de ticket</li>
                            <li>numéro de badge</li>
                            <li>numéro de plaque</li>
                            <li>numéro de code QR</li>
                        </ul>
                    </>
                }
                it={
                    <>
                        Criteri di ricerca disponibili:
                        <ul>
                            <li>numero del ticket</li>
                            <li>numero del badge</li>
                            <li>numero di targa</li>
                            <li>numero del codice QR</li>
                        </ul>
                    </>
                }
                en={
                    <>
                        Available search criteria:
                        <ul>
                            <li>ticket number</li>
                            <li>badge number</li>
                            <li>number plate number</li>
                            <li>QR code number</li>
                        </ul>
                    </>
                }
            />
        </Box>
    );
}
