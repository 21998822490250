import { Box, InputLabel } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export function ReadOnlyTextField({
    label,
    value,
    sx,
}: {
    label: React.ReactNode;
    value: React.ReactNode;
    sx?: SxProps<Theme>;
}) {
    return (
        <Box>
            <InputLabel sx={{ lineHeight: '1em', paddingTop: '1px' }}>
                {label}
            </InputLabel>
            <Box
                sx={{
                    color: theme => theme.palette.blue.main,
                    fontWeight: '500',
                    fontSize: '16px',
                    paddingBottom: '5px',
                    ...sx,
                }}
            >
                {value}
            </Box>
        </Box>
    );
}
