import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { Response } from 'dg-web-shared/lib/HttpResponse.ts';
import * as ServerStateSlice from 'dg-web-shared/lib/ServerStateSlice.ts';
import * as Http from '../../api/Http.ts';
import * as AsyncRequest from '../../AsyncRequest.ts';
import * as SettingsState from './SettingsState.ts';
import { ExistingOffstreetTransactionsType } from './CurrentOperatorLoginState.ts';

export interface OperatorData {
    operatorName: string;
    correspondenceLanguage: string;
    mgmtOrgAddress: OrganizationAddress;
    ownerOrgAddress: OrganizationAddress | null;
    invoiceAddress: InvoiceAddress;
    billingProperties: BillingProperties;
    settings: Settings;
    licensePlatePermitSettings: LicensePlatePermitSettings;
    apiKey: string;
    hasEnforcement: boolean;
    hasNightParking: boolean;
    hasTaxomexModule: boolean;
    hasParkingpayModule: boolean;
    enforcementOnly: boolean;
    taxomexParkMonitorIframe: boolean;
    taxomexMiofficeIframe: boolean;
    parkomaticSmartfolio: boolean;
    tk001aValue: number | null;
    tk002aValue: number | null;
    tk003aValue: number | null;
    hasAWhitelistCustomerPermittype: boolean;
    hasAPermittypeCustomerGrant: boolean;
    requireCounterPaymentChannel: boolean;
    hasTicketFunctionality: boolean;
    numberOfActiveTenants: number;
    existingOffstreetTransactionsType: ExistingOffstreetTransactionsType | null;
    hasOnstreetTransactions: boolean;
    hasActiveOnstreetZone: boolean;
    hasCloudConnector: boolean;
    hasParkingaboUserInitialBalanceActive: boolean;
    hasContractVersionAVBV2: boolean;
    hasActiveCounters: boolean;
    hasParkingaboUsageReportData: boolean;
    hasActiveParkingaboProducts: boolean;
}

export interface OrganizationAddress {
    name1: string;
    name2: string | null;
    street: string;
    zipCode: string;
    city: string;
}

export interface InvoiceAddress {
    name1: string | null;
    name2: string | null;
    street: string | null;
    zipCode: string | null;
    city: string | null;
}

export interface Account {
    dtaAccountId: number;
    iban: string;
    dtaText: string;
}

interface BillingProperties {
    billAsMail: boolean;
    accounts: Account[];
}

interface Settings {
    transactions: boolean;
    activityStatement: boolean;
    customerWhitelistOverview: boolean;
    carTypeDescription: boolean;
}

export enum FieldSetting {
    hidden = 'hidden',
    optional = 'optional',
    required = 'required',
}

export interface LicensePlatePermitSettings {
    whitelistActive: boolean;
    active: boolean;
    poolEnforcementTime: number;
    whitelistPersonalNumber: FieldSetting;
    whitelistContractNumber: FieldSetting;
    hasLicensePlatePermits: boolean;
    hasBadgePermits: boolean;
    hasClearanceRequests: boolean;
    hasSalaryDeductionExport: boolean;
    hasMobilityStatisticExport: boolean;
    permitWhitelistInfoPerPermittype: boolean;
}

export type State = ServerStateSlice.ServerState<OperatorData | null>;

export const { get, setResponse, reset } =
    ServerStateSlice.generateServerState<OperatorData | null>(
        'common-OperatorDataState',
        () => null,
        (store: Flux.Store, state: State) => {
            if (state.shouldFetch) {
                store.update(fetchOperatorData);
            }
        },
        p => p,
    );

const fetchOperatorData = AsyncRequest.request(
    Http.OperatorAccount.ReferenceData.getReferenceData,
    (store: Flux.Store, res: Response): string => {
        setResponse(store, res);
        if (res.statusCode.cls.success) {
            const state = get(store);
            if (!!state.data && state.data.correspondenceLanguage) {
                new SettingsState.StateSlice(store).writeLanguageFromServer(
                    state.data.correspondenceLanguage,
                );
            }
        }
        return 'OperatorDataState-fetchOperatorData';
    },
);
