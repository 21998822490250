import { useState } from 'react';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import { ParkingaboUser } from './OperatorParkingaboUsersForm.tsx';
import {
    RequestStatus,
    useServerFetch,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Localized } from '../common/components/Localized.tsx';
import { Person } from '@mui/icons-material';
import { getCustomerDisplayName } from 'dg-web-shared/common/models/Users.tsx';
import {
    generatePath,
    Outlet,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import {
    ConfirmationDialog,
    ConfirmationDialogVersions,
} from '../operator-logins/components/ConfirmationDialog.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import {
    ParkingaboUserContext,
    useParkingaboUserOutletContext,
} from './OperatorParkingaboUsers.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { CustomerTenantState } from 'dg-web-shared/model/CustomerTenantState.ts';

export enum ParkingaboUserInfoTab {
    DETAILS = 'DETAILS',
    PRODUCT = 'PRODUCT',
    VEHICLES = 'VEHICLES',
    ACCOUNT_STATEMTENT = 'ACCOUNT_STATEMTENT',
}

export function OperatorParkingaboUsersDetails() {
    const { customerNr } = useParams();
    const { tenants, customerLifecycleState, setCustomerLifecycle } =
        useParkingaboUserOutletContext();
    if (!customerNr) {
        throw new Error('customerNr must be provided');
    }
    const [detailState, refetchDetails] = useServerFetch<
        ParkingaboUser,
        { customerNr: string },
        null
    >(
        context => ({
            url: `/ui-api/operator-account/parkingabo/customer/${context.customerNr}`,
        }),
        { customerNr: customerNr },
    );
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    useServerSuccessEffect(customerLifecycleState, refetchDetails);
    return (
        <>
            <OperatorRoutedModal
                variant={ModalVariant.BIG}
                backUrl={`/parkingabo/users${makeSearchQueryWithFilters(
                    searchText,
                    activeFilters,
                )}`}
                render={controller => (
                    <OperatorAsyncLoadedSection
                        requestState={detailState}
                        render={parkingaboUser => (
                            <ParkingaboUserDetailOutlet
                                context={{
                                    parkingaboUser: parkingaboUser,
                                    refetchParkingaboUser: refetchDetails,
                                    tenants: tenants,
                                    customerLifecycleState:
                                        customerLifecycleState,
                                    setCustomerLifecycle: setCustomerLifecycle,
                                    onDirtyStateChange:
                                        controller.setShouldConfirmBeforeLeave,
                                    onClose: controller.close,
                                    navigateWithinModal:
                                        controller.navigateWithinModal,
                                }}
                            />
                        )}
                    />
                )}
            />
        </>
    );
}

export function useParkingaboUserDetailOutletContext() {
    return useOutletContext<ParkingaboUserDetailContext>();
}

function ParkingaboUserDetailOutlet({
    context,
}: {
    context: ParkingaboUserDetailContext;
}) {
    return <Outlet context={context} />;
}

export interface ParkingaboUserDetailContext extends ParkingaboUserContext {
    parkingaboUser: ParkingaboUser;
    refetchParkingaboUser: () => void;
    onDirtyStateChange: (shouldConfirm: boolean) => void;
    onClose: () => void;
    navigateWithinModal: (url: string) => void;
}

enum UserActions {
    LOCK = 'LOCK',
    UNLOCK = 'UNLOCK',
    ARCHIVE = 'ARCHIVE',
    REACTIVATE = 'REACTIVATE',
}

export function UserDeailsSearchBar({
    button,
    refreshButton,
}: {
    button?: JSX.Element;
    refreshButton: JSX.Element;
}) {
    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.blue.medium,
                height: 55,
                display: 'flex',
                justifyContent: 'space-between',
                padding: theme => theme.spacing(0, 3),
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                }}
            >
                {refreshButton}
                {button}
            </Box>
        </Box>
    );
}

export function DetailHeader() {
    const {
        onClose,
        parkingaboUser,
        setCustomerLifecycle,
        customerLifecycleState,
    } = useParkingaboUserDetailOutletContext();
    const [openUserActionWarning, setOpenUserActionWarning] =
        useState<UserActions | null>(null);

    const userArchived = parkingaboUser.state === CustomerTenantState.ARCHIVED;
    const userLocked = parkingaboUser.state === CustomerTenantState.LOCKED;

    return (
        <>
            <HeaderComponent
                title={getCustomerDisplayName(parkingaboUser, false)}
                Icon={Person}
                buttons={
                    <HeaderActionCloseButtons
                        onClose={onClose}
                        dropdownItems={[
                            {
                                key: 'lock',
                                label: <LockTitle />,
                                disabled:
                                    customerLifecycleState.status ===
                                        RequestStatus.PENDING ||
                                    userArchived ||
                                    userLocked,
                                onClickCallback: () =>
                                    setOpenUserActionWarning(UserActions.LOCK),
                            },
                            {
                                key: 'unlock',
                                label: <UnlockTitle />,
                                disabled:
                                    customerLifecycleState.status ===
                                        RequestStatus.PENDING || !userLocked,
                                onClickCallback: () =>
                                    setOpenUserActionWarning(
                                        UserActions.UNLOCK,
                                    ),
                            },
                            { key: 'divider', divider: true },
                            {
                                key: 'archive',
                                label: <ArchiveTitle />,
                                disabled:
                                    customerLifecycleState.status ===
                                        RequestStatus.PENDING || userArchived,
                                onClickCallback: () =>
                                    setOpenUserActionWarning(
                                        UserActions.ARCHIVE,
                                    ),
                            },
                            {
                                key: 'reactivate',
                                label: <ReactivateTitle />,
                                disabled:
                                    customerLifecycleState.status ===
                                        RequestStatus.PENDING ||
                                    !parkingaboUser.canReactivateCustomer,
                                onClickCallback: () =>
                                    setOpenUserActionWarning(
                                        UserActions.REACTIVATE,
                                    ),
                            },
                        ]}
                    />
                }
                editMode={false}
            />
            <ArchiveWarningModal
                open={openUserActionWarning === UserActions.ARCHIVE}
                parkingaboUser={parkingaboUser}
                onConfirm={() => {
                    setCustomerLifecycle({
                        customerNr: parkingaboUser.customerNr,
                        state: CustomerTenantState.ARCHIVED,
                    });
                    setOpenUserActionWarning(null);
                }}
                onCancel={() => setOpenUserActionWarning(null)}
            />
            <ReactivateWarningModal
                open={openUserActionWarning === UserActions.REACTIVATE}
                onConfirm={() => {
                    setCustomerLifecycle({
                        customerNr: parkingaboUser.customerNr,
                        state: CustomerTenantState.ACTIVE,
                    });
                    setOpenUserActionWarning(null);
                }}
                onCancel={() => setOpenUserActionWarning(null)}
            />

            <LockUserWarningModal
                open={openUserActionWarning === UserActions.LOCK}
                parkingaboUser={parkingaboUser}
                onConfirm={() => {
                    setCustomerLifecycle({
                        customerNr: parkingaboUser.customerNr,
                        state: CustomerTenantState.LOCKED,
                    });
                    setOpenUserActionWarning(null);
                }}
                onCancel={() => setOpenUserActionWarning(null)}
            />
            <UnlockUserWarningModal
                open={openUserActionWarning === UserActions.UNLOCK}
                parkingaboUser={parkingaboUser}
                onConfirm={() => {
                    setCustomerLifecycle({
                        customerNr: parkingaboUser.customerNr,
                        state: CustomerTenantState.ACTIVE,
                    });
                    setOpenUserActionWarning(null);
                }}
                onCancel={() => setOpenUserActionWarning(null)}
            />
        </>
    );
}

export function DetailTabs({
    parkingaboUserInfoTab,
    customerNr,
    tenantHasDigitalPurchase,
}: {
    parkingaboUserInfoTab: ParkingaboUserInfoTab;
    customerNr: string;
    tenantHasDigitalPurchase: boolean;
}) {
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const queryFilters = makeSearchQueryWithFilters(searchText, activeFilters);
    const { navigateWithinModal } = useParkingaboUserDetailOutletContext();
    return (
        <TabContext value={parkingaboUserInfoTab}>
            <AppBar position="static" elevation={0}>
                <Tabs
                    value={parkingaboUserInfoTab}
                    onChange={(_e, value) => {
                        switch (value as ParkingaboUserInfoTab) {
                            case ParkingaboUserInfoTab.DETAILS:
                                navigateWithinModal(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/detail',
                                        {
                                            customerNr: customerNr,
                                        },
                                    )}${queryFilters}`,
                                );
                                break;
                            case ParkingaboUserInfoTab.PRODUCT:
                                navigateWithinModal(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/products',
                                        {
                                            customerNr: customerNr,
                                        },
                                    )}${queryFilters}`,
                                );
                                break;
                            case ParkingaboUserInfoTab.VEHICLES:
                                navigateWithinModal(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/vehicles',
                                        {
                                            customerNr: customerNr,
                                        },
                                    )}${queryFilters}`,
                                );
                                break;
                            case ParkingaboUserInfoTab.ACCOUNT_STATEMTENT:
                                navigateWithinModal(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/account-statement',
                                        {
                                            customerNr: customerNr,
                                        },
                                    )}${queryFilters}`,
                                );
                                break;
                        }
                    }}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    <Tab
                        style={{
                            marginLeft: 24,
                        }}
                        label={
                            <Localized
                                de="Details"
                                fr="Détails"
                                it="Dettagli"
                                en="Details"
                            />
                        }
                        value={ParkingaboUserInfoTab.DETAILS}
                    />
                    <Tab
                        label={
                            <Localized
                                de="Produkte"
                                fr="Produits"
                                it="Prodotti"
                                en="Products"
                            />
                        }
                        value={ParkingaboUserInfoTab.PRODUCT}
                    />
                    <Tab
                        label={
                            <Localized
                                de="Fahrzeuge"
                                fr="Véhicules"
                                it="Veicoli"
                                en="Vehicles"
                            />
                        }
                        value={ParkingaboUserInfoTab.VEHICLES}
                    />
                    {tenantHasDigitalPurchase && (
                        <Tab
                            label={
                                <Localized
                                    de="Kontoauszug"
                                    fr="Relevé de compte"
                                    it="Estratto conto"
                                    en="Account statement"
                                />
                            }
                            value={ParkingaboUserInfoTab.ACCOUNT_STATEMTENT}
                        />
                    )}
                </Tabs>
            </AppBar>
        </TabContext>
    );
}

function ArchiveWarningModal({
    open,
    parkingaboUser,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    parkingaboUser: ParkingaboUser;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={<ArchiveTitle />}
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <Localized
                    de="Benutzer archivieren"
                    fr="Archiver l'utilisateur"
                    it="Archiviare l'utente"
                    en="Archive user"
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie dieses Benutzerkonto wirklich archivieren?"
                    fr="Voulez-vous vraiment archiver cet compte utilisateur?"
                    it="Vuole davvero archiviare questo conto utente?"
                    en="Do you really want to archive this user account?"
                />
                <ul>
                    {parkingaboUser.hasActiveOrFutureProducts && (
                        <li>
                            <Localized
                                de="alle vorhandenen Produkte werden annulliert"
                                fr="tous les produits existants sont annulés"
                                it="tutti i prodotti esistenti verranno annullati"
                                en="all existing products will be cancelled "
                            />
                        </li>
                    )}
                    {parkingaboUser.hasNegativeSaldo && (
                        <li>
                            <Localized
                                de="der negative Saldo wird ausgebucht"
                                fr="le solde négatif est décomptabilisé à perte"
                                it="il saldo negativo verrà messo a perdita"
                                en="the negative balance will be posted as a loss"
                            />
                        </li>
                    )}
                    {parkingaboUser.hasAppLogin &&
                        !parkingaboUser.hasSharedLogin && (
                            <>
                                <li>
                                    <Localized
                                        de="der Zugriff auf die Parkingabo-App wird gelöscht"
                                        fr="l'accès à l'app Parkingabo sera annulé"
                                        it="l'accesso all'app Parkingabo verrà annullato"
                                        en="access to the Parkingabo app will be cancelled"
                                    />
                                </li>
                                <li>
                                    <Localized
                                        de="das Zahlungsmittel wird gelöscht"
                                        fr="le moyen de paiement sera supprimé"
                                        it="il mezzo di pagamento verrà cancellato"
                                        en="the payment means will be deleted"
                                    />
                                </li>
                            </>
                        )}
                    {parkingaboUser.hasSharedLogin && (
                        <>
                            <li>
                                <Localized
                                    de="das Benutzerkonto wird in der Parkingabo-App nicht mehr sichtbar sein und das entsprechende Zahlungsmittel wird gelöscht"
                                    fr="le compte utilisateur ne sera plus visible dans l'app Parkingabo et le moyen de paiement correspondant sera supprimé"
                                    it="il conto utente non sarà più visibile nell’app Parkingabo e il relativo mezzo di pagamento verrà cancellato"
                                    en="The user account will no longer be visible in the Parkingabo app and the corresponding payment method will be deleted"
                                />
                            </li>
                            <li>
                                <Localized
                                    de="der Zugriff auf die Parkingabo-App bleibt aktiv, da es von Konten anderer Betreiber mitbenutzt wird"
                                    fr="l'accès à l'app Parkingabo reste actif, car il est partagé avec les comptes d'autres exploitants"
                                    it="l'accesso all'app Parkingabo rimane attivo, in quanto condiviso con conti di altri gestori"
                                    en="access to the Parkingabo app remains active, as it is shared with accounts of other operators"
                                />
                            </li>
                        </>
                    )}
                </ul>
            </p>
        </ConfirmationDialog>
    );
}

function ReactivateWarningModal({
    open,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={<ReactivateTitle />}
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <Localized
                    de="Benutzer reaktivieren"
                    fr="Réactiver l'utilisateur'"
                    it="Riattivare utente"
                    en="Reactivate user"
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie dieses Benutzerkonto wirklich reaktivieren?"
                    fr="Voulez-vous vraiment réactiver cet compte utilisateur?"
                    it="Vuole davvero riattivare questo conto utente?"
                    en="Do you really want to reactivate this user account?"
                />
            </p>
        </ConfirmationDialog>
    );
}

function ReactivateTitle() {
    return (
        <Localized
            de="Reaktivieren"
            fr="Réactiver"
            it="Riattiva"
            en="Reactivate"
        />
    );
}

function ArchiveTitle() {
    return (
        <Localized de="Archivieren" fr="Archiver" it="Archivia" en="Archive" />
    );
}

function LockUserWarningModal({
    open,
    parkingaboUser,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    parkingaboUser: ParkingaboUser;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={<LockTitle />}
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <Localized
                    de="Benutzer sperren"
                    fr="Bloquer l'utilisateur"
                    it="Bloccare utente"
                    en="Lock user"
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie dieses Benutzerkonto wirklich sperren? Wenn ein Konto gesperrt ist, können keine neuen Produkte hinzugefügt werden und bestehende Produkte sind suspendiert (nicht gültig)."
                    fr="Voulez-vous vraiment bloquer cet compte utilisateur? Lorsqu'un compte est bloqué, il est impossible d'ajouter de nouveaux produits et les produits existants sont suspendus (non valables)."
                    it="Vuole davvero bloccare questo conto utente? Quando un conto è bloccato non è possibile aggiungere nuovi prodotti e quelli già esistenti sono sospesi (non validi)."
                    en="Do you really want to lock this user account? When an account is locked, new products cannot be added and existing products are suspended (not valid)."
                />
                {parkingaboUser.hasAppLogin && <EmailNotificationText />}
            </p>
        </ConfirmationDialog>
    );
}

function UnlockUserWarningModal({
    open,
    parkingaboUser,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    parkingaboUser: ParkingaboUser;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={<UnlockTitle />}
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <Localized
                    de="Benutzer entsperren"
                    fr="Débloquer l'utilisateur'"
                    it="Sbloccare utente"
                    en="Unlock user"
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie dieses Benutzerkonto wirklich entsperren?"
                    fr="Voulez-vous vraiment débloquer cet compte utilisateur?"
                    it="Vuole davvero sbloccare questo conto utente?"
                    en="Do you really want to unlock this user account?"
                />
                {parkingaboUser.hasAppLogin && <EmailNotificationText />}
            </p>
        </ConfirmationDialog>
    );
}

function UnlockTitle() {
    return (
        <Localized de="Entsperren" fr="Débloquer" it="Sblocca" en="Unlock" />
    );
}

function LockTitle() {
    return <Localized de="Sperren" fr="Bloquer" it="Blocca" en="Lock" />;
}

function EmailNotificationText() {
    return (
        <p>
            <b>
                <Localized
                    de="Der Benutzer wird per E-Mail benachrichtigt."
                    fr="L'utilisateur sera informé par e-mail."
                    it="L'utente verrà avvisato via e-mail."
                    en="The user will be notified by e-mail."
                />
            </b>
        </p>
    );
}
