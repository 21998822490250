import { Colors } from 'dg-web-shared/ui/vars.ts';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { Button } from '@mui/material';

export function SubmitButton({
    loading,
    label,
    sx,
    disabled,
    onClick,
    dirtyForm,
}: {
    loading: boolean;
    label: React.ReactNode;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    dirtyForm?: boolean;
}) {
    return (
        <Button
            variant="contained"
            type="submit"
            disabled={loading || disabled}
            onClick={onClick}
            color={'primary'}
            sx={
                dirtyForm
                    ? {
                          ...sx,
                          '&:hover': {
                              background: Colors.white,
                              color: theme => theme.palette.blue.main,
                          },
                      }
                    : sx
            }
        >
            {loading ? (
                <PresetLoadingSpinner
                    color="inherit"
                    preset={LoadingSpinnerPresets.EmbedInButton}
                />
            ) : (
                label
            )}
        </Button>
    );
}
