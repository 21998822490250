import { Outlet, useParams } from 'react-router-dom';
import {
    RequestMethod,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import {
    ApprovingContractInfo,
    BarrierGateIdentificationEntity,
    OffstreetTransactionActions,
    OffstreetTransactionRefundBlockedExplanation,
} from './OperatorOffstreetTransactionTypes.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import {
    ApprovingContractInfoField,
    BarrierGateIdentificationEnityValue,
    NoReceiptForRunningContract,
    OffstreetTransactionActionText,
    OffstreetTransactionOpen,
    OffstreetTransactionRefundBlocked,
    OperatorOffstreetTransactionDetailRoutedModal,
} from './OperatorOffstreetTransactionShared.tsx';
import { Box } from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { BarrierGateIdentification } from '../common/components/IdentificationItems.tsx';
import {
    ConfirmationDialog,
    ConfirmationDialogVersions,
} from '../operator-logins/components/ConfirmationDialog.tsx';
import { useState } from 'react';
import { TerminalProvider } from '../common/state/ParkingsState.ts';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { PaymentChannel } from '../common/models/PaymentChannel.ts';
import {
    NicerPaymentChannel,
    useOffstreetTransactionsOutlet,
} from './OperatorOffstreetTransactionsRoute.tsx';
import {
    BlockLabel,
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';

interface EntryWithoutExitVehicle {
    barrierGateProvider: TerminalProvider | null;
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    resolvedAt: string | null;
}

export interface PoolFullInfo {
    poolFullContractId: number;
    poolFullPermitTypeName: string;
    poolFullEntryWithoutExitVehicles: EntryWithoutExitVehicle[];
}

enum BarrierGateAntipassbackType {
    DISABLED = 'disabled',
    AUTOCLEARING_24H = 'autoclearing_24h',
    AUTOCLEARING_12H = 'autoclearing_12h',
    MANUAL_CLEARING = 'manual_clearing',
}

interface EntryWithoutExitOffstreetTransactionDetails {
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    barrierGateProvider: TerminalProvider | null;
    refundBlockedExplanation: OffstreetTransactionRefundBlockedExplanation;
    zoneName: string;
    start: string;
    entryWithoutExitId: number;
    antipassbackType: BarrierGateAntipassbackType;
    antipassbackActive: boolean;
    approvingContractInfo: ApprovingContractInfo | null;
    poolFullInfo: PoolFullInfo | null;
    paymentChannel: PaymentChannel;
}

export function OperatorOffstreetTransactionEntryWithoutExit() {
    const urlParams = useParams<{ entryWithoutExitId: string }>();
    const { refetchList } = useOffstreetTransactionsOutlet();
    const entryWithoutExitId = urlParams.entryWithoutExitId
        ? parseInt(urlParams.entryWithoutExitId, 10)
        : null;
    const [offstreetTransactionsState, refetchOffstreetTransaction] =
        useServerFetch<
            EntryWithoutExitOffstreetTransactionDetails,
            { entryWithoutExitId: number },
            null
        >(
            context => ({
                url: `/ui-api/operator-account/offstreet-transactions/entry-without-exit/${context.entryWithoutExitId}`,
            }),
            entryWithoutExitId ? { entryWithoutExitId } : null,
        );

    return (
        <OperatorOffstreetTransactionDetailRoutedModal
            render={controller => (
                <>
                    <OperatorAsyncLoadedSection
                        requestState={offstreetTransactionsState}
                        render={transaction => (
                            <TransactionEntryWithoutExitDetailContent
                                transaction={transaction}
                                onClose={controller.close}
                                refetchList={refetchList}
                                refetchOffstreetTransaction={
                                    refetchOffstreetTransaction
                                }
                            />
                        )}
                    />

                    <Outlet />
                </>
            )}
        />
    );
}

interface CancelEntryPayload {
    ids: number[];
}

function TransactionEntryWithoutExitDetailContent({
    transaction,
    onClose,
    refetchList,
    refetchOffstreetTransaction,
}: {
    transaction: EntryWithoutExitOffstreetTransactionDetails;
    onClose: () => void;
    refetchList: () => void;
    refetchOffstreetTransaction: () => void;
}) {
    const [cancelEntryState, cancelEntry] = useServerWrite<
        CancelEntryPayload,
        null
    >(() => ({
        url: `/ui-api/operator-account/offstreet-transaction/entry/cancel`,
        method: RequestMethod.DELETE,
    }));
    const [cancelEntryDialogOpen, setCancelEntryDialogOpen] = useState(false);
    useServerSuccessEffect(cancelEntryState, () => {
        refetchList();
        onClose();
    });

    const [disableAntipassbackState, disableAntipassback] = useServerWrite<
        { entryWithoutExitId: number },
        null
    >(({ entryWithoutExitId }) => ({
        url: `/ui-api/operator-account/offstreet-transaction/entry/${entryWithoutExitId}/disable-antipassback`,
    }));
    const [disableAntipassbackDialogOpen, setDisableAntipassbackDialogOpen] =
        useState(false);
    useServerSuccessEffect(disableAntipassbackState, () => {
        refetchOffstreetTransaction();
    });

    function cancelEntryOrDisableAntipassbackAction() {
        if (
            transaction.barrierGateProvider !== TerminalProvider.CLOUD_CONNECTOR
        ) {
            return {
                key: 'cancel-entry',
                label: (
                    <OffstreetTransactionActionText
                        action={OffstreetTransactionActions.CANCEL_ENTRY}
                    />
                ),
                onClickCallback: () => setCancelEntryDialogOpen(true),
                disabled: false,
            };
        }

        const antipassbackDisabled =
            transaction.antipassbackType ===
            BarrierGateAntipassbackType.DISABLED;

        return {
            key: 'disable-antipassback',
            label: (
                <OffstreetTransactionActionText
                    action={OffstreetTransactionActions.DISABLE_ANTIPASSBACK}
                />
            ),
            onClickCallback: () => setDisableAntipassbackDialogOpen(true),
            disabled: antipassbackDisabled,
            tooltip: antipassbackDisabled ? (
                <AntipassbackAlreadyDisabled />
            ) : undefined,
        };
    }

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    title={
                        <Localized
                            de="Parkvorgang"
                            fr="Procédure"
                            it="Procedura"
                            en="Procedure"
                        />
                    }
                    editMode={false}
                    buttons={
                        <HeaderActionCloseButtons
                            onClose={onClose}
                            dropdownItems={[
                                cancelEntryOrDisableAntipassbackAction(),
                                {
                                    key: 'reimburse',
                                    label: (
                                        <OffstreetTransactionActionText
                                            action={
                                                OffstreetTransactionActions.REIMBURSE_CUSTOMER
                                            }
                                        />
                                    ),
                                    onClickCallback: () => {},
                                    disabled: true,
                                    tooltip: (
                                        <OffstreetTransactionRefundBlocked
                                            refundBlockedExplanation={
                                                transaction.refundBlockedExplanation
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: 'download-receipt',
                                    label: (
                                        <OffstreetTransactionActionText
                                            action={
                                                OffstreetTransactionActions.DOWNLOAD_RECEIPT
                                            }
                                        />
                                    ),
                                    onClickCallback: () => {},
                                    disabled: true,
                                    tooltip: <NoReceiptForRunningContract />,
                                },
                            ]}
                        />
                    }
                />
            }
            body={
                <FormContentLayout>
                    <FormLeftColumn>
                        <CancelEntryConfirmationDialog
                            onConfirm={() =>
                                cancelEntry({
                                    ids: [transaction.entryWithoutExitId],
                                })
                            }
                            onCancel={() => setCancelEntryDialogOpen(false)}
                            open={cancelEntryDialogOpen}
                        />
                        <DisableAntipassbackConfirmationDialog
                            onConfirm={() =>
                                disableAntipassback({
                                    entryWithoutExitId:
                                        transaction.entryWithoutExitId,
                                })
                            }
                            onCancel={() =>
                                setDisableAntipassbackDialogOpen(false)
                            }
                            open={disableAntipassbackDialogOpen}
                        />
                        <OffstreetTransactionOpen
                            isPresent={true}
                            hasAntipassback={transaction.antipassbackActive}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            }
                            value={
                                <BarrierGateIdentificationEnityValue
                                    barrierGateIdentificationEntity={
                                        transaction.barrierGateIdentificationEntity
                                    }
                                />
                            }
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Beginn"
                                    fr="Début"
                                    it="Inizio"
                                    en="Start"
                                />
                            }
                            value={Formatter.dayMonthYearHourMinute(
                                DateTime.fromISO(transaction.start),
                            )}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Ende"
                                    fr="Fin"
                                    it="Fine"
                                    en="End"
                                />
                            }
                            value={EmDash}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Dauer"
                                    fr="Durée"
                                    it="Durata"
                                    en="Duration"
                                />
                            }
                            value={EmDash}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            }
                            value={transaction.zoneName}
                        />
                    </FormLeftColumn>
                    <FormRightColumn>
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Preis"
                                    fr="Prix"
                                    it="Prezzo"
                                    en="Price"
                                />
                            }
                            value={EmDash}
                        />
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Kanal"
                                    fr="Canal"
                                    it="Canale"
                                    en="Channel"
                                />
                            }
                            value={
                                <NicerPaymentChannel
                                    paymentChannel={transaction.paymentChannel}
                                />
                            }
                        />
                        <ApprovingContractInfoField
                            approvingContractInfo={
                                transaction.approvingContractInfo
                            }
                            poolFullInfo={transaction.poolFullInfo}
                        />
                        {transaction.poolFullInfo && (
                            <PoolFullEntryWithoutExitVehicles
                                poolFullEntryWithoutExitVehicles={
                                    transaction.poolFullInfo
                                        .poolFullEntryWithoutExitVehicles
                                }
                            />
                        )}
                        <ReadOnlyTextField
                            label={
                                <Localized
                                    de="Antipassback-Typ"
                                    fr="Type antipassback"
                                    it="Tipo antipassback"
                                    en="Antipassback type"
                                />
                            }
                            value={
                                <AntipassbackType
                                    barrierGateAntipassbackType={
                                        transaction.antipassbackType
                                    }
                                />
                            }
                        />
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                            }}
                        >
                            <BlockLabel
                                label={
                                    <Localized
                                        de="Erstellt am"
                                        fr="Créé le"
                                        it="Creato il"
                                        en="Created at"
                                    />
                                }
                            >
                                {EmDash}
                            </BlockLabel>
                            <BlockLabel label="ID">{EmDash}</BlockLabel>
                        </Box>
                    </FormRightColumn>
                </FormContentLayout>
            }
        />
    );
}

function PoolFullEntryWithoutExitVehicles({
    poolFullEntryWithoutExitVehicles,
}: {
    poolFullEntryWithoutExitVehicles: EntryWithoutExitVehicle[];
}) {
    if (poolFullEntryWithoutExitVehicles.length == 0) {
        return null;
    }
    const entity2Key = (entity: BarrierGateIdentificationEntity): string => {
        switch (entity.identificationMedia) {
            case BarrierGateIdentification.BADGE:
                return entity.labelNr;
            case BarrierGateIdentification.LICENSE_PLATE:
                return entity.licenseNumber;
            case BarrierGateIdentification.QR_CODE:
                return entity.qrCodeId;
            case BarrierGateIdentification.TICKET:
                return entity.ticketApiString;
        }
    };
    return (
        <ReadOnlyTextField
            label={
                <Localized
                    de="Anwesende Fahrzeuge"
                    fr="Véhicules présents"
                    it="Veicoli presenti"
                    en="Vehicles present"
                />
            }
            value={poolFullEntryWithoutExitVehicles.map(vehicle => (
                <Box
                    key={entity2Key(vehicle.barrierGateIdentificationEntity)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Box sx={{ marginRight: '35px' }}>
                        <BarrierGateIdentificationEnityValue
                            barrierGateIdentificationEntity={
                                vehicle.barrierGateIdentificationEntity
                            }
                            barrierGateProvider={vehicle.barrierGateProvider}
                        />
                    </Box>
                    {vehicle.resolvedAt ? (
                        <>
                            <Localized
                                de="(bis "
                                fr="(jusqu'au "
                                it="(fino al "
                                en="(until "
                            />
                            {DateTime.fromISO(vehicle.resolvedAt).toFormat(
                                'dd.MM.yyyy – HH:mm',
                            ) + ')'}
                        </>
                    ) : (
                        <Localized
                            de="(noch anwesend)"
                            fr="(toujours présent)"
                            it="(ancora presente)"
                            en="(still present)"
                        />
                    )}
                </Box>
            ))}
        />
    );
}

function AntipassbackType({
    barrierGateAntipassbackType,
}: {
    barrierGateAntipassbackType: BarrierGateAntipassbackType | null;
}): JSX.Element {
    switch (barrierGateAntipassbackType) {
        case BarrierGateAntipassbackType.DISABLED:
        case null:
            return <>{EmDash}</>;

        case BarrierGateAntipassbackType.AUTOCLEARING_24H:
            return (
                <Localized
                    de="aktiv in den ersten 24h"
                    fr="actif les premières 24h"
                    it="attivo le prime 24h"
                    en="active the first 24h"
                />
            );
        case BarrierGateAntipassbackType.AUTOCLEARING_12H:
            return (
                <Localized
                    de="aktiv in den ersten 12h"
                    fr="actif les premières 12h"
                    it="attivo le prime 12h"
                    en="active the first 12h"
                />
            );

        case BarrierGateAntipassbackType.MANUAL_CLEARING:
            return (
                <Localized
                    de="aktiv bis auf Widerruf"
                    fr="actif jusqu'à révocation"
                    it="attivo fino a revoca"
                    en="active until revoked"
                />
            );
    }
}

function CancelEntryConfirmationDialog({
    open,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={
                <Localized
                    de="Bestätigen"
                    fr="Confirmer"
                    it="Conferma"
                    en="Confirm"
                />
            }
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <OffstreetTransactionActionText
                    action={OffstreetTransactionActions.CANCEL_ENTRY}
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie diese Einfahrt wirklich annulieren?"
                    fr="Voulez-vous vraiment supprimer cette entrée?"
                    it="Vuole veramente cancellare questa entrata?"
                    en="Do you really want to delete this entry?"
                />
            </p>
        </ConfirmationDialog>
    );
}

function DisableAntipassbackConfirmationDialog({
    open,
    onConfirm,
    onCancel,
}: {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <ConfirmationDialog
            open={open}
            confirmLabel={
                <Localized
                    de="Deaktivieren"
                    fr="Désactiver"
                    it="Disattiva"
                    en="Deactivate"
                />
            }
            onConfirm={onConfirm}
            cancelLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onCancel={onCancel}
            title={
                <OffstreetTransactionActionText
                    action={OffstreetTransactionActions.DISABLE_ANTIPASSBACK}
                />
            }
            dialogVersion={ConfirmationDialogVersions.HARD_WARNING}
        >
            <p>
                <Localized
                    de="Möchten Sie den Antipassback für diesen Parkvorgang wirklich deaktivieren?"
                    fr="Voulez-vous vraiment désactiver l'antipassback pour cette procédure?"
                    it="Vuole veramente disattivare l'anti-passback per questa procedura?"
                    en="Do you really want to deactivate the anti-passback for this park transaction?"
                />
            </p>
        </ConfirmationDialog>
    );
}

export function AntipassbackAlreadyDisabled() {
    return (
        <Localized
            de="Antipassback ist für diesen Parkvorgang bereits inaktiv"
            fr="L'anti-passback est déjà inactif pour cette procédure"
            it="L'anti-passback è già inattivo per questa procedura"
            en="Antipassback is already inactive for this park transaction"
        />
    );
}
